import { css } from 'styled-components'
import { media } from './utils'

// Legacy Styles

export const legacyHeading1 = css`
    font-family: "StyreneA";
    font-size: 36px;
    line-height: 1.28;
    
    ${media.phone`
        font-size: 26px;
    `}
`

export const legacyHeading2 = css`
    font-family: "StyreneA";
    font-size: 26px;
    line-height: 1.38;

    ${media.phone`
        font-size: 18px;
    `}
`

export const legacyHeading3 = css`
    font-family: 'Formula';
    font-size: 56px;
    line-height: 1.32;
    letter-spacing: 2.8px;
    text-transform: uppercase;
`

// New Styles

export const h1 = css`
    font-family: 'Formula';
    font-size: 72px;
    line-height: 1.2;
    
    ${media.phone`
        font-size: 40px;
    `}
`

export const h2 = css`
    font-family: 'Formula';
    font-size: 36px;
    line-height: 1;
    
    ${media.phone`
        font-size: 32px;
    `}
`

export const h3 = css`
    font-family: 'Formula';
    font-size: 32px;
    line-height: 1.2;
`

export const h4 = css`
    font-family: 'StyreneA';
    font-size: 18px;
    line-height: 1.2;
`

export const h5 = css`
    font-family: 'StyreneA';
    font-size: 13px;
    line-height: 1.2;
    
    ${media.phone`
        font-size: 13px;
    `}
`

export const body = css`
    font-family: "Swiss721BT";
    font-size: 16px;
    line-height: 26px;

    ${media.phone`
        font-size: 13px;
        line-height: 1.5;
    `}
`

export const subheading = css`
    font-size: 12px;
    line-height: 1.25;
`

export const para2 = css`
    font-family: 'StyreneA';
    font-size: 30px;
    line-height: 39px;

    ${media.phone`
        font-size: 18px;
        line-height: 25.2px;
    `}
`

export const para1 = css`
    font-family: 'StyreneA';
    font-size: 26px;
    line-height: 1.4;

    ${media.tablet`
        font-size: 18px;
    `}
`