import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../../styles/global'
import { slateGrey } from '../../../styles/colours'
import { subheading, legacyHeading2, h3 } from '../../../styles/type'

const BlockSubheading = (props) => {
    const { heading, white } = props;

	const renderContent = () => {
		if (!heading) return;

		return (
			<Subheading
				dangerouslySetInnerHTML={{__html: heading}}
			/>
		)
	}

    return (
		<Wrapper color={white ? 'white' : 'black'}>
            {renderContent()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled(motion.div)``
const Icon = styled.div``

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 0 40px;
	border-top: 1px dashed black;

	${media.phone`
		padding: 14px 0 34px;
	`}

	${Subheading} {
		${h3}
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}

	${(props) => {
        if (props.color == 'white') {
            return css`
				border-color: white;
				
				${Subheading} {
					color: white
				}
            `
        }
    }}
`

BlockSubheading.wrapper = css`
	margin-bottom: 0 !important;
	max-width: ${maxWidth}px;
    margin: 0 auto;
`

export default BlockSubheading