import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { useMount } from 'react-use';

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, bodyLink, maxWidth } from '../../../styles/global'
import { slateGrey, yellow } from '../../../styles/colours'
import { subheading, legacyHeading2, para1, para2, body, h4 } from '../../../styles/type'
import { Link } from 'gatsby';


const BlockColumnTextImage = (props) => {
    const { columns } = props;
	const [isDevice, setIsDevice] = useState(false)
	const detectDevice = useBreakpoint('tablet');

    useMount(() => {
		setIsDevice(detectDevice)
	})

	useEffect(() => {
		setIsDevice(detectDevice)
	}, [detectDevice])

	const renderColumns = (params) => {
		if (!columns) return;
		
		const items = columns.map((item, i) => {

			return (
				<>
					{item?.type == 'none' && !detectDevice && (
						<Column spacer={item?.type == 'none'}>
							<Spacer/>
						</Column>
					)}
		
					{item?.type == 'media' && (
						<Column 
							format={item.media_format}
							className={[ i == 0 ? 'left-column' : 'right-column', item?.constrain_width_mobile ? 'constrain-width-mobile' : '']}
						>
							{item?.media_format == 'image' && (
								<ImageWrapper
									constrain={item?.constrain_width_mobile}
								>
									<Image
										className={'image'}
										key={item?.image && item?.image.sizes.medium2}
										src={item?.image && item?.image.sizes.medium2}
									>
										{(src, loading) => {
											return (
												<Img 
													src={src} 
													style={{opacity: loading ? 0 : 1}}  
												/>
											)
										}}
									</Image> 

									{item?.use_caption && item?.caption && (
										<Caption
											className={'caption'}
											dangerouslySetInnerHTML={{__html: item?.caption}}
										/>
									)}

									{item?.link_text && item?.link?.url && (
										<Link
											to={
											item?.link?.target === '_blank' && item?.link?.url.includes('.pdf')
											  ? `https://bkkarchitects.wpenginepowered.com/${item?.link?.url}`
											  : item?.link?.url
										  }
										>
											{item?.link_text}
										</Link>
									)}
								</ImageWrapper>		
							)}

							{item?.media_format == 'link_card' && (
								<ImageWrapper>
									{item?.image && (
										<Image
											className={'image'}
											key={item?.image && item?.image.sizes.medium2}
											src={item?.image && item?.image.sizes.medium2}
										>
											{(src, loading) => {
												return (
													<Img 
														src={src} 
														style={{opacity: loading ? 0 : 1}}  
													/>
												)
											}}
										</Image> 
									)}

									{item?.use_caption && item?.caption && (
										<Caption
											className={'caption'}
											dangerouslySetInnerHTML={{__html: item?.caption}}
										/>
									)}

									{item?.link_text && item?.link?.url && (
										<Link
											to={
											item?.link?.target === '_blank' && item?.link?.url.includes('.pdf')
											  ? `https://bkkarchitects.wpenginepowered.com/${item?.link?.url}`
											  : item?.link?.url
										  }
										  	target='_blank'
										>
											{item?.link_text}
										</Link>
									)}
								</ImageWrapper>		
							)}

							{item?.media_format == 'video' && (
								<VideoWrapper
									constrain={item?.constrain_width_mobile}
								>
									<Video
										url={item.video}
										className={'media'}
										playing
										loop
										muted
										playsinline
										styles={{
											width: '100%',
											height: '100%'
										}}
									/>

									{item?.use_caption && item?.caption && (
										<Caption
											className={'caption'}
											dangerouslySetInnerHTML={{__html: item?.caption}}
										/>
									)}
								</VideoWrapper>
							)}
						</Column>
					)}
		
					{item?.type == 'text' && (
						<Column format={item.text_format}>

							{item?.use_intro && item?.text_intro && item.text_format != 'quotation' && (
								<Intro 
									className='intro'
									dangerouslySetInnerHTML={{__html: item.text_intro}} 
								/>
							)}

							{item?.text_content && (
								<Text 
									dangerouslySetInnerHTML={{__html: item.text_content}} 
								/>
							)}

							{item?.credit && (
								<Text className='credit' dangerouslySetInnerHTML={{__html: item?.credit}} />
							)}
						</Column>
					)}
				</>
			)
		})

		return (
			<Columns>
				{items}
			</Columns>
		)
	}

    return (
		<Wrapper>
			{renderColumns()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Icon = styled.div``
const Spacer = styled.div``
const Text = styled.div``
const Intro = styled.div``
const Button = styled(Link)``

// Utility

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
`

const Img = styled.img`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	background: grey;
	display: flex;
`

const VideoWrapper = styled.div`
	position: relative;
`

const Video = styled(ReactPlayer) `
	width: 100% !important;
	height: auto !important;
	pointer-events: none;
	margin-bottom: 8px;

	video {
		object-fit: cover;
	}

	${props => {
		if (props.shouldContain) {
			return css`
				video {
					object-fit: contain;
				}
			`
		}
	}}
`

// Layout

const Wrapper = styled.div`
	display: flex;
`

// Columns

const Columns = styled.div`
	display: flex;
	gap: 8vw;
	width: 100%;

	${media.tablet`
		gap: 80px;
	`}

	${media.phone`
		gap: 40px;
		flex-direction: column;
	`}
`

const Column = styled.div`
	display: flex;

	img {
		width: 100%;
		object-fit: contain;
	}

	${Spacer}{
		width: 300px;
	}

	${props => {
		if (props.spacer){
			return css`
				${media.tablet`
					display: none;
				`}
			`
		}

		if (props.format == 'body'){
			return css`
				flex-direction: column;
				padding-bottom: 40px;
				flex-direction: column;

				${media.phone`
					flex: 1 0 auto;
					padding-bottom: 0;
				`}

				${Heading} {
					p {
						${para1}
					}
				}

				${Text} {
					padding-botom: 40px;
					${body}

					h3 {
						font-weight: 600;
					}

					a {
						${bodyLink}
					}

					ul {
						list-style-type: none;
						padding: 0;
						margin: 0;
					}
				
					li {
						&:before {
							content: '– ';
							color: #000;
						}
					}
				}

				${Intro} {
					p {
						${para1}
					}
				}
			`
		}

		if (props.format == 'image' || props.format == 'video'){
			return css`
				width: 100%;
				margin-bottom: 40px;
				min-width: 300px;
				align-self: flex-start;

				&.left-column {
					${media.phone`
						order: 1;
						align-self: flex-end;
					`}
				}

				&.constrain-width-mobile {
					${media.phone`
						max-width: 160px;
					`}
				}

				${media.phone`
					min-width: auto;
					margin-bottom: 0;
				`}

				${Img}{
					margin-bottom: 8px;
				}
			`
		}

		if (props.format == 'intro'){
			return css`
				flex: 1 1 70%;

				${media.tablet`
					flex: 1 1 53%;
				`}

				${media.phone`
					flex: 1 0 auto;
				`}

				${Text}{
					p {
						${para1}
					}
				}
			`
		}

		if (props.format == 'quotation') {
			return css`
				display: flex;
				flex-direction: column;

				${media.tablet`
					flex: 1 0 53%;
				`}

				${media.phone`
					flex: 1 0 auto;
				`}

				${Text} {
					p {
						${para2}
					}

					&.credit {
						p {
							${para1}
							font-size: 24px;

							${media.phone`
								font-size: 15px;
							`}
						}
						
						a {
							text-decoration: underline;

							&:hover {
								color: ${slateGrey};
							}
						}
					}
				}
			`
		}

		if (props.format == 'link_card'){
			return css`
				background: ${yellow};
				padding: 30px;
				height: fit-content;
				margin-bottom: 40px;

				width: 100%;
				max-width: 300px;
				min-width: 240px;

				&.left-column {
					${media.phone`
						order: 1;
						align-self: flex-end;
					`}
				}

				${media.phone`
					min-width: 100%;
					margin-bottom: 0;
				`}

				${Img}{
					margin-bottom: 20px;
				}

				.caption {
					font-size: 14px;
					padding-bottom: 20px;

					&, p {
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 0.12px;
					}
				}

				a {
					display: flex;
					justify-content: space-between;
					align-items: center;
					${h4}

					${media.tablet`
						height: 12px;
					`}

					&:after {
						content: '';
						width: 20px;
						height: 12px;
						background-image: url(${require('../../../assets/images/icon-arrow.svg')});
						background-repeat: no-repeat;
					}
				}
			`
		}
	}}
`

const Caption = styled.div`
	&, p {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.12px;

		a {
			${bodyLink}
		}
	}
`


BlockColumnTextImage.wrapper = css`
	max-width: ${maxWidth}px;
    margin: 0 auto 160px;

	${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 80px;
    `}
`

export default BlockColumnTextImage