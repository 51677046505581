import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { container, padding, bgImage, bgIcon, hoverState, pagePadding, pageHeading, blockPadding, pagePaddingTopIndex } from '../../../styles/global'
import { legacyHeading1, legacyHeading2, subheading, h3, body } from '../../../styles/type'
import { yellow, slateGrey, paleGrey, coolGrey } from '../../../styles/colours'
import { media, isClient } from '../../../styles/utils'

const BlockRelated = (props) => {
    const { heading, contentType, data } = props;

    const renderRelatedContent = () => {

        console.log('renderRelatedContent', data)

        const items = data.map((item, i) => {
            return (
                <Item 
                    as={'a'} 
                    href={`/${contentType}/${item?.slug}`}
                    key={i}
                >
                    {item?.acf?.listing?.use_image_or_video !== 'video' && item?.acf?.listing?.image && (
                        <Image
                            key={item?.acf?.listing?.image && item?.acf?.listing?.image.sizes.medium2}
                            src={item?.acf?.listing?.image && item?.acf?.listing?.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <Img 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}

                    {item?.acf?.listing?.use_image_or_video === 'video' && item?.acf?.listing?.video && (
                        <Player 
                            url={item?.acf?.listing?.video}
                            controls={false}
                            loop
                            playing 
                            muted
                            playsinline
                        />
                    )}

                    <Info>
                        <Subheading
                            dangerouslySetInnerHTML={{__html: contentType == 'projects' ? item?.title : item?.primary_cat.name}}
                        />

                        <Heading
                            dangerouslySetInnerHTML={{__html: contentType == 'projects' ? item?.acf.content_title : item?.title}}
                        />
                    </Info>
                </Item>
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    return (
        <Related type={contentType} className="related-stories">
            <Heading>{heading}</Heading>
            {renderRelatedContent()}
        </Related>
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Item = styled.div``
const ItemLink = styled.a``
const Items = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
`

const Img = styled.img`
	${bgIcon};
	transition: opacity 0.45s ease;
	background: grey;
	width: 100%;
	height: 100%;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Related

const Related = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${blockPadding}
	padding-top: 80px;
	padding-bottom: 112px;

	${media.phone`
		padding-bottom: 50px;
	`}

	${Heading} {
		${h3}
		text-transform: uppercase;
		letter-spacing: 0.05em;
		color: white;
		
		${media.phone`
			font-size: 32px;
			padding: 0;
		`}
	}

	${Items} {
		margin-top: 31px;
		display: flex;
		gap: 40px;

		${media.tablet`
			flex-direction: column;
		`}
	}

	${Item}{
		flex: 1;
		display: flex;
		flex-direction: column;

		${Img}{
			margin-bottom: 20px;
			height: 480px;
			object-fit: cover;
			width: 100%;

            ${media.tablet`
                height: 452px;
            `}

            ${media.phone`
                height: 245px;
            `}
		}

		${Heading} {
			${legacyHeading2}
			color: white;
			letter-spacing: auto;
			text-transform: none;

            ${media.tablet`
                max-width: 520px;
            `}
		}
	
		${Subheading} {
			${body}
			color: white;
			padding-bottom: 12px;
		}
	}

    ${props => {
        if(props.type == 'journal') return css`
            background: ${coolGrey};

            ${Heading}, ${Subheading} {
                color: black !important;
            }

            ${Item} {
                &:hover {
                    ${Heading}, ${Subheading} {
                        color: ${paleGrey} !important;
                    }
                }
            }
        `

        if(props.type == 'projects') return css`
            background: black;
            color: white;

            ${Items}{
                ${Item} {
                    &:hover {
                        ${Heading}, ${Subheading} {
                            color: ${coolGrey} !important;
                        }
                    }

                    ${Image}{
                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                    ${Info}{}
                    ${Heading}{}
                    ${Subheading}{}
                }
            }
        `
    }}
`

// Video

const Player = styled(ReactPlayer)`
	width: 100% !important;
	height: 100% !important;
	pointer-events: none;	
    margin-bottom: 20px;
    height: 480px !important;

    ${media.tablet`
        height: 452px !important;
    `}

    ${media.phone`
        height: 245px !important;
    `}

	video {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
`

BlockRelated.wrapper = css`
    margin-bottom: 0;

    ${media.phone`
		margin-bottom: 0px;
	`}
`

export default BlockRelated