import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media, useBreakpoint } from '../../../styles/utils'
import Block from '../../Block'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../../styles/global'
import { paleGrey, coolGrey, slateGrey, placeholder } from '../../../styles/colours'
import { body } from '../../../styles/type'

const MOBILE_LIMIT = 4

const BlockTeamGrid = (props) => {
    const { heading, people } = props;
    const isMobile = useBreakpoint('phone')
    const [mobileExpanded, setMobileExpanded] = useState(false)

    const renderTeam = () => {
        if (!people?.length) return

        const items = people.map((item, i) => {
            if (isMobile && i >= MOBILE_LIMIT && !mobileExpanded) return
            if (!item) return

            return (
                <Block 
                    layout={'team_member'}
                    display
                    key={i}
                    {...item}
                />
            )
        })

        return (
            <People>
                {items}
            </People>
        )
    }

    return (
        <Wrapper>
            <Block
                layout={'sub_heading'}
                heading={heading}
                display
            />

            {renderTeam()}

            {isMobile && !mobileExpanded && people?.length > MOBILE_LIMIT && (
                <Button
                    onClick={() => setMobileExpanded(true)}
                >
                    <Text>{'View All'}</Text>
                    <Icon/>
                </Button>
            )}
        </Wrapper>
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Text = styled.div``
const Icon = styled.div``

// Layout

const Wrapper = styled.div`
	display: flex;
    flex-direction: column;

	${media.phone`
		flex-direction: column;
	`}

    .block_sub_heading {
        margin: 0;
    }
`

const People = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    flex-wrap: wrap;
    gap: 30px 40px;

    ${media.tablet`
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 16px;
    `}

    ${media.phone`
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 16px;
    `}
`

const Button = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    cursor: pointer;

    ${Text} {
        ${body}
        padding-bottom: 2px;
    }

    ${Icon} {
        width: 12px;
        height: 12px;
        background-image: url(${require('../../../assets/images/icon-chevron.svg')});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
`

BlockTeamGrid.wrapper = css`
    max-width: ${maxWidth}px;
    margin-left: auto;
    margin-right: auto;
`

export default BlockTeamGrid