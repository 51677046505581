import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth, bodyLink } from '../../../styles/global'
import { slateGrey, yellow } from '../../../styles/colours'
import { subheading, body } from '../../../styles/type'

const BlockMedia = (props) => {
	const { image, use_caption, caption, type, portrait_items, media_format, video, media_position, acf_fc_layout} = props;

	const renderMedia = (data) => {
		if (!data) return

		const items = data.map((item, i) => {
			return (
				<Item format={item?.media_format} className={`${item?.media_format}`}>
					{item?.media_format == 'image' && (
						<ImageWrapper>
							<Image
								key={item?.image && item?.image.sizes.medium2}
								src={item?.image && item?.image.sizes.medium2}
							>
								{(src, loading) => {
									return (
										<Img 
											src={src} 
											style={{opacity: loading ? 0 : 1}}  
										/>
									)
								}}
							</Image> 

							{item?.use_caption && item?.caption && (
								<Caption
									className={'caption'}
									dangerouslySetInnerHTML={{__html: item?.caption}}
								/>
							)}
						</ImageWrapper>		
					)}

					{item?.media_format == 'video' && (
						<Video
							url={item.video}
							className={'media'}
							playing
							loop
							muted
							playsinline
							styles={{
								width: '100%',
								height: '100%'
							}}
						/>
					)}
				</Item>
			)
		})
		
		return (
			<List>
				{items}
			</List>
		)
	}
		
	return (
		<Wrapper type={type} fullwidth={acf_fc_layout == 'full_width_landscape_media'}>
			{acf_fc_layout == 'full_width_landscape_media' && media_format == 'image' && (
				<ImageWrapper type={type}>
					<Image
						key={image && image.sizes.medium2}
						src={image && image.sizes.medium2}
					>
						{(src, loading) => {
							return (
								<Img 
									src={src} 
									style={{opacity: loading ? 0 : 1}}  
								/>
							)
						}}
					</Image> 

					{use_caption && caption && (
						<Caption
							type={type}
							className={'caption'}
							dangerouslySetInnerHTML={{__html: caption}}
						/>
					)}
				</ImageWrapper>	
			)}

			{acf_fc_layout == 'full_width_landscape_media' && media_format == 'video' && (
				<>
				<Video
					fullwidth={acf_fc_layout == 'full_width_landscape_media'}
					url={video}
					className={'media'}
					playing
					loop
					muted
					playsinline
					styles={{
						width: '100%',
						height: '100%'
					}}
				/>

				{use_caption && caption && (
					<Caption
						type={type}
						className={'caption'}
						dangerouslySetInnerHTML={{__html: caption}}
					/>
				)}
				</>
			)}

			{type == 'landscape_full' && media_format == 'image' && (
					<ImageWrapper type={type}>
						<Image
							key={image && image.sizes.medium2}
							src={image && image.sizes.medium2}
						>
							{(src, loading) => {
								return (
									<Img 
										src={src} 
										style={{opacity: loading ? 0 : 1}}  
									/>
								)
							}}
						</Image> 

						{use_caption && caption && (
							<Caption
								type={type}
								className={'caption'}
								dangerouslySetInnerHTML={{__html: caption}}
							/>
						)}
					</ImageWrapper>	
				)}

			{type == 'landscape_full' && media_format == 'video' && (
				<Video
					url={video}
					className={'media'}
					playing
					loop
					muted
					playsinline
					styles={{
						width: '100%',
						height: '100%'
					}}
				/>
			)}

			{type == 'landscape_contained' && media_format == 'image' && (
				<Columns type={type} mediaPosition={media_position}>
					<Column className={'none'}>
						<Spacer/>
					</Column>

					<ImageWrapper type={type}>
						<Image
							key={image && image.sizes.medium2}
							src={image && image.sizes.medium2}
						>
							{(src, loading) => {
								return (
									<Img 
										src={src} 
										style={{opacity: loading ? 0 : 1}}  
									/>
								)
							}}
						</Image> 

						{use_caption && caption && (
							<Caption
								type={type}
								className={'caption'}
								dangerouslySetInnerHTML={{__html: caption}}
							/>
						)}
					</ImageWrapper>	
				</Columns>
			)}

			{type == 'landscape_contained' && media_format == 'video' && (
				<Columns type={type} position={media_position}>
					<Column className={'none'}>
						<Spacer/>
					</Column>

					<Video
						url={video}
						className={'media'}
						playing
						loop
						muted
						playsinline
						styles={{
							width: '100%',
							height: '100%'
						}}
					/>
				</Columns>
			)}

			{type == 'portrait_cols' && portrait_items && (
				<>
					{renderMedia(portrait_items)}
				</>
			)}
		</Wrapper>
	)
}

// Shared

const Column = styled.div``
const List = styled.div``
const Item = styled.div``
const Spacer = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
	width: 100%;

	${props => {
		if (props.type == 'landscape_full') {
			return css`
				${Img}{
					// width: 100vw;
				}
			`
		}
	}}
`

const Img = styled.img`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	background: grey;
	display: flex;

	width: 100%;
	height: 100%;
`

const Video = styled(ReactPlayer) `
	width: 100% !important;
	height: 100% !important;
	pointer-events: none;

	video {
		object-fit: cover;
	}
`

// Layout

const Wrapper = styled.div`
	${List}{
		display: flex;
		gap: 40px;
		max-width: ${maxWidth}px;
		margin: 0 auto;

		${media.phone`
			flex-direction: column;
		`}

		${Item}{
			flex-basis: 33.33%;

			${media.phone`
				flex-basis: 100%;
			`}

			&.none {
				${media.phone`
					display: none;
				`}
			}
		}
	}

	${props => {
		if (props.fullwidth == true) {
			return css`
				display: block;
				margin: 0 -96px 0;

				${media.tablet`
					margin: 0 -32px 0;
				`}

				${media.tablet`
					margin: 0 -20px 0;
				`}

				${Caption}{
					padding: 0 96px;
					max-width: ${maxWidth}px;
					margin: 8px auto 160px;

					a {
						${bodyLink};
					}

					${media.tablet`
						max-width: 100%;
						padding: 0 32px;
					`}

					${media.phone`
						max-width: 100%;
						padding: 0 20px;
						margin: 16px 0 0;
					`}
				}
			`
		}

		if (props.type == 'landscape_full') {
			return css`
				max-width: ${maxWidth}px;
				margin: 0 auto;
			`
		}
	}}
`

const Columns = styled.div`
	display: flex;
	gap: 8vw;
	max-width: ${maxWidth}px;
	margin: 23px auto 160px;

	${media.tablet`
		gap: 80px;
		max-width: 100%;
	`}

	${media.phone`
		gap: 40px;
		flex-direction: column;
	`}

	${props => {
		if (props.mediaPosition == 'left') {
			return css`
				flex-direction: row-reverse;

				${media.phone`
					gap: 0;
				`}
			`
		}

		if (props.mediaPosition == 'right') {
			return css`
				flex-direction: row;

				${media.phone`
					gap: 0;
				`}
			`
		}
	}}

	${Column}{
		display: flex;

		img {
			width: 100%;
			object-fit: contain;
		}

		${Spacer}{
			width: 300px;

			${media.tablet`
				display: none;
			`}
		}

		&.none {
			${media.phone`
				display: none;
			`}
		}
	}

	${ImageWrapper}{
		display: flex;
		flex-direction: column;
	}

`

const Caption = styled.div`
	margin-top: 8px;

	&, p {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.12px;

		${media.tablet`
			font-size: 14px;
		`}
	}

	${props => {
		if (props.type == 'landscape_full') {
			return css`
				padding: 0 96px;
				max-width: 940px;

				${media.tablet`
					padding: 0 32px;
				`}

				${media.tablet`
					padding: 0 20px;
				`}
			`
		}
	}}
`


BlockMedia.wrapper = css`
	&.block_full_width_landscape_media {
		.block-container {
			max-width: 100% !important;
		}
	}

	${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 80px;
    `}
`

export default BlockMedia
