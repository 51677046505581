import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store/configureStore'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";
import HeaderContext from './src/components/Header/HeaderContext';

export default ({ element, props  }) => {
    return (
        <Provider 
            store={store}
        >   
            <PortalProvider>
                    <HeaderContext>
                        <GlobalStyles/>
                        {element}
                    </HeaderContext>
                </PortalProvider>
            </Provider>
    )
}