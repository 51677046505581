import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { slateGrey } from '../../../styles/colours'
import { subheading, legacyHeading2 } from '../../../styles/type'

const LIST_LIMIT = 8;

const BlockText = (props) => {
	const { content, layout } = props;
	const [expanded, setExpanded] = useState(false)
	const [hasHidden, setHasHidden] = useState(false)
	const isPhone = useBreakpoint('phone');

	const renderColumns = (params) => {
		if (!content) return;
		
		return content.map((column, i) => {
			let columnContent;

			if (layout == '2x_text_columns') {
				columnContent = (
					<Description
						dangerouslySetInnerHTML={{__html: column.text}}
					/>
				)
			}

			if (layout == '3x_text_columns') {		
				let items = column.list.map((item, i) => {
					if (i >= LIST_LIMIT && !hasHidden) setHasHidden(true)

					return (
						<Item 
							key={i}
							animate={!expanded && i >= LIST_LIMIT ? 'hidden' : 'active'}
							variants={animatedItem}
						>
							<Subheading>{item.text}</Subheading>
							<Subheading>{item.year}</Subheading>
						</Item>
					)
				})

				columnContent = (
					<>
						<Items>{items}</Items>
					</>
				)
			}

			// Switch 3x_text_columns to accordion layout on phone 

			if (layout == '3x_text_columns' && isPhone) return

			return (
				<Column 
					key={i}
				>
					<Subheading>
						{column.heading}
					</Subheading>

					{columnContent}
				</Column>
			)
		})
	}


	const renderAccordionItems = (items, itemActive) => {
		const options = items.map((item, i) => {
			return (
				<AccordionItem 
					key={i}
				>
					<Subheading>{item.text}</Subheading>	
					<Subheading>{item.year}</Subheading>
				</AccordionItem>
			)
		})

		return (
			<AccordionItems
				animate={itemActive ? 'active' : 'hidden'}
				initial={'hidden'}
				variants={animatedAccordionItem}
			>
				{options}
			</AccordionItems>
		)
	}

	const renderAccordion = (params) => {
		if (!isPhone || layout !== '3x_text_columns' || !content) return;

		const items = content.map((item, i) => {
			const [itemActive, setItemActive] = useState(false)

			return (
				<Filter 
					key={i}
				>
					<Toggle
						active={itemActive}
						onClick={() => setItemActive(!itemActive)}
					>
						<Heading>{item.heading}</Heading>
						<Icon/>
					</Toggle>

					{renderAccordionItems(item.list, itemActive)}
				</Filter>
			)
		})

		return (
			<Accordion>
				<Items>
					{items}
				</Items>
			</Accordion>
		)
	}
	        
	return (
		<Wrapper>
			<Columns>
				{renderColumns()}
				{renderAccordion()}
			</Columns>

			{layout == '3x_text_columns' && !isPhone && hasHidden && (
				<ReadMore
					onClick={() => setExpanded(!expanded)}
				>
					{expanded ? 'Show Less' : 'Show More'}
				</ReadMore>
			)}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled(motion.div)``
const Icon = styled.div``

// Column

const Columns = styled.div`
	display: flex;

	${media.phone`
		flex-direction: column;
	`}
`

const Column = styled.div`
	flex: 1;

	> ${Subheading} {
		${subheading}
		margin-bottom: 76px;
	}

	> ${Description} {
		&, p {
            ${legacyHeading2}

            a {
                ${hoverState}
            }
        }
	}

	${Items} {
		display: flex;
		flex-direction: column;

		${Item} {
			display: flex;
			justify-content: space-between;

			${Subheading} {
				${subheading}
			}

			>:first-child {
				padding-right: 40px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&:not(:last-child) {
		padding-right: 64px;
	}

	${media.phone`
		> ${Subheading} {
			margin-bottom: 30px;
		}
		
		&:not(:last-child) {
			padding-right: 0;
			margin-bottom: 50px;
		}
	`}
`

// Expanded

const animatedItem = {
	hidden: { 
		opacity: 0, 
		height: 0,
		marginBottom: 0,
		pointerEvents: 'none',
		transition: {
			ease: 'easeOut'
		}
	},
	active: { 
		opacity: 1,
		marginBottom: 24,
		height: 'auto',
		pointerEvents: 'auto',
		transition: {
			ease: 'easeOut'
		}
	}
}

const ReadMore = styled.div`
     ${subheading}
	margin-top: 32px;
	user-select: none;
	${hoverState}
`

// Accordion

const Accordion = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	transform: translateX(-30px);

	${Heading} {
		${subheading}
	}

	> ${Heading} {
		${padding}

		${media.phone`
			padding: 0 16px;
		`}
	}

	${Items} {
		display: flex;
		flex-direction: column;
		border-top: 1px dashed ${slateGrey};
	}
`

const Toggle = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	cursor: pointer;
	padding: 0 16px;

	${Icon} {
		width: 16px;
		height: 16px;
		${bgIcon};
	}

	${props => {
		if (props.active) return css`
			${Icon} {
				background-image: url(${require('../../../assets/images/filter-minus.svg')});
			}
		`

		if (!props.active) return css`
			${Icon} {
				background-image: url(${require('../../../assets/images/filter-plus.svg')});
			}
		`
	}}
`

const Filter = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 9px;
	user-select: none;
	border-bottom: 1px dashed ${slateGrey};
	overflow: hidden;

	${Heading} {
		color: ${slateGrey}
	}
`

const animatedAccordionItem = {
	hidden: { 
		opacity: 0, 
		height: 0,
		marginTop: 0,
		marginBottom: 0,
		pointerEvents: 'none',
		transition: {
			ease: 'easeOut'
		}
	},
	active: { 
		opacity: 1,
		height: 'auto',
		marginTop: 10,
		marginBottom: 2,
		pointerEvents: 'auto',
		transition: {
			ease: 'easeOut'
		}
	}
}

const AccordionItems = styled(motion.div)`
	display: flex;
	padding: 0 16px;	
	flex-direction: column;
	align-items: flex-start;
`

const AccordionItem = styled.div`
	display: flex;
	justify-content: space-between;

	${Subheading} {
		${subheading}
		color: ${slateGrey}
	}

	>:first-child {
		padding-right: 40px;
	}

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	&:first-child {
		margin-top: 10px;
	}

	&:last-child {
		margin-bottom: 14px;
	}
`

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`


BlockText.wrapper = css`

`

export default BlockText
