import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { Block } from '../Block'
import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { paleGrey, coolGrey, slateGrey, placeholder } from '../../../styles/colours'
import { subheading, legacyHeading2, legacyHeading3, body } from '../../../styles/type'
import Modal from '../../Modal'
import TeamMemberProfile from '../../Modal/TeamMemberProfile'

const BlockGridItem = (props) => {
    const { acf, slug, title } = props;
    const modalManager = Modal.useModals()

    console.log('BlockGridItem', props)

    const toggleModal = () => {
        if(!acf?.show_profile_page) return
        modalManager.open((modal) => {
            return (
                <Modal 
                    {...modal}
                    data={acf} 
                    name={title}
                    modalStyles={modalStyles}
                    modal={TeamMemberProfile}
                    backgroundOpacity={0.01}
                    slideRight
                />
            )
        })
    }

    const renderOnlyProfileCard = () => {
        return (
            <Wrapper>
                <ImageWrapper>
                    {acf?.image && (
                        <Image
                            key={acf?.image && acf?.image.sizes.medium2}
                            src={acf?.image && acf?.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <Img 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}

                    <Info>
                        <Wrap>
                            <Subheading
                                dangerouslySetInnerHTML={{__html: title}}
                            />

                            {acf?.position && (
                                <Subheading
                                    dangerouslySetInnerHTML={{__html: acf?.position}}
                                />
                            )}
                        </Wrap>
                    </Info>

                </ImageWrapper>		
            </Wrapper>
        )
    }

    const renderProfile = () => {
        return (
            <Wrapper onClick={() => {toggleModal()}}>
                <ImageWrapper>
                    {acf?.image && (
                        <Image
                            key={acf?.image && acf?.image.sizes.medium2}
                            src={acf?.image && acf?.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <Img 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}

                    <Info>
                        <Wrap>
                            <Subheading
                                dangerouslySetInnerHTML={{__html: title}}
                            />

                            {acf?.position && (
                                <Subheading
                                    dangerouslySetInnerHTML={{__html: acf?.position}}
                                />
                            )}
                        </Wrap>

                        <Icon>{'+'}</Icon>
                    </Info>

                </ImageWrapper>		
            </Wrapper>
        )
    }

    return (
        <>
            {acf?.show_profile_page == true ? renderProfile() : renderOnlyProfileCard()}
        </>
    )
}

// Shared

const Subheading = styled.h2``
const Description = styled.div``
const Wrap = styled.div``
const Icon = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
    display: flex;
    flex-direction: column;
`

const Img = styled.img`
	${bgIcon};
	transition: opacity 0.45s ease;
	background: grey;
	width: 100%;
	height: 100%;
    object-fit: cover;
`

// Layout

const Wrapper = styled.div`
	display: flex;
    width: fit-content;

	${media.phone`
		flex-direction: column;
	`}

    &:hover {
        cursor: pointer;

        ${ImageWrapper}{
            ${Info}{
                ${Icon}, ${Subheading}{
                    color: ${coolGrey};
                }
            }
        }
    }
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    ${media.phone`
        margin-top: 8px;
    `}

    ${Wrap}{
        display: flex;
        flex-direction: column;

        ${Subheading}{
            ${body};
        }
    }

    ${Icon}{
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 18px;
    }
`

// Modal Styles

const modalStyles = css`
    justify-content: flex-start;
`

BlockGridItem.wrapper = css`
    margin-bottom: 0 !important;
    max-width: 100%;
`

export default BlockGridItem