import { 
    BlockText,
    BlockGridItem,
    BlockSlider,
    BlockImage,
    BlockTextColumns,
    BlockImageHotspot,
    BlockIntro,
    BlockTwoColumnContent,
    BlockThreeColumnContent,
    BlockSubheading,
    BlockMedia,
    BlockRelated,
    BlockPageTitle,
    BlockTeamGrid,
    BlockTeamMember,
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'grid_item': BlockGridItem,
        'slider': BlockSlider,
        'image_slider': BlockSlider,
        'image': BlockImage,
        '2x_text_columns': BlockTextColumns,
        '3x_text_columns': BlockTextColumns,
        'image_hotspot': BlockImageHotspot,
        'intro': BlockIntro,
        'colx2_text_image': BlockTwoColumnContent,
        'colx3_text_image': BlockThreeColumnContent,
        'sub_heading': BlockSubheading,
        'media_items': BlockMedia,
        'full_width_landscape_media': BlockMedia,
        'related': BlockRelated,
        'page_title': BlockPageTitle,
        'team_grid': BlockTeamGrid,
        'team_member': BlockTeamMember
    }
    
    return blocks[layout]
}


