import React, { useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import usePortal from 'react-useportal'
import OutsideClickHandler from 'react-outside-click-handler';
import { useMount, useUnmount } from 'react-use';

export const modalContext = createContext();

const Modal = (props) => {
	const { className, children, modal: Modal, backgroundOpacity, ...rest } = props;
	const [mounted, setMounted] = useState(false)

	useMount(() => {
		document.body.style.overflow = 'hidden'
        setTimeout(() => setMounted(true), 0)
	})

	useUnmount(() => {
		document.body.style.overflow = 'auto'
	})

	const onModalClose = () => {
		setMounted(false)

		setTimeout(() => {
			rest?.close()
		}, 500)
	}

	return (
		<Wrapper
			className={mounted && 'mounted'}
			modalStyles={props?.modalStyles}
			slideRight={props?.slideRight}
			backgroundOpacity={backgroundOpacity}
		>
			<OutsideClickHandler
				onOutsideClick={onModalClose}
			>
				{Modal && (
					<Modal
						{...rest}
						close={onModalClose}
					/>
				)}
			</OutsideClickHandler>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0,0,0, 0.4);
	z-index: 15;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.card {
		min-width: 500px;
	}

	/* Animation */
	
	transition: opacity ease 0.35s;
    will-change: opacity;
    opacity: 1;

    &.mounted {
        opacity: 1;

        .card {
            transform: scale(1);
        }
    }

	/* Alter background opacity */
    
    ${(props) => {
        if (props.backgroundOpacity) {
            return css`
                background: rgba(0,0,0, ${props.backgroundOpacity});
            `
        }
    }}

	/* Slide Right */

    ${(props) => {
        if (props.slideRight) {
            return css`
            	${slideRightAnim} 
            `
        }
    }}

	${props => props?.modalStyles}
`

const slideRightAnim = css`
    align-items: flex-start;
    transition: opacity 0.6s ease;
	transition-delay: 1s;

    > * {
		transition: transform 0.6s ease;
        transform: translateX(-100%);
        will-change: transform;
    }             

    &.mounted {
        > * {
            transform: translateX(0);
        }
    }  
`


export default Modal
