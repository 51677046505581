import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { sample, compact } from 'lodash'
import { Element, scroller } from 'react-scroll';
import moment from 'moment'

import { container, padding, bgImage, hoverState, pagePadding, pageHeading, blockPadding, breadcrumb } from '../../../styles/global'
import { legacyHeading1, legacyHeading2, subheading, h4, body } from '../../../styles/type'
import { media } from '../../../styles/utils'

const BlockPageTitle = (props) => {
    const { breadcrumb, h1, title, textColor, date } = props

    return (
        <Container color={textColor}>
            <Breadcrumb as={h1 ? 'h1' : 'h2'}>
                {breadcrumb}
            </Breadcrumb>

            {date && (
                <Date
                    as={'h2'}
                    dangerouslySetInnerHTML={{__html: date}}
                />
            )}

            <Title
                as={'h2'}
                dangerouslySetInnerHTML={{__html: title}}
            />
        </Container>
    )
}

const Container = styled.div`
    ${blockPadding}
    padding-top: 160px;
    padding-bottom: 90px;

    ${media.phone`
        padding-top: 100px;
        padding-bottom: 55px;
    `}

    ${props => props.color && css`
        color: ${props.color};
    `}
`

const Breadcrumb = styled.div`
    ${breadcrumb}
`

const Date = styled.div`
    ${h4}
    margin-bottom: 40px;		
`

const Title= styled.div`
    &, p {
        font-family: 'Formula';
        font-size: 72px;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        max-width: 1000px;

        ${media.phone`
            font-size: 40px;
            line-height: 95%;
        `}
    }
`

BlockPageTitle.wrapper = css`
    margin: 0 !important;
`

export default BlockPageTitle