import React, { useState, useEffect } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { orderBy, forEach } from 'lodash'
import moment from 'moment'
import Fade from 'react-reveal/Fade';
import { useMount } from 'react-use';

import { media, useBreakpoint } from '../../styles/utils'
import { coolGrey } from '../../styles/colours'
import { container, bgIcon, padding, pagePaddingTopIndex, breadcrumb, blockPadding } from '../../styles/global'
import { h2, h4, subheading } from '../../styles/type'
import ReactPlayer from 'react-player'

const PostIndex = (props) => {
	const { pageTheme, breadcrumb } = props;
	const [filters, setFilters] = useState([])
	const [activeFilter, setActiveFilter] = useState(false)
	const indexRef = React.useRef(null);

	// detect if tablet

	const [isPhone, setIsPhone] = useState(false)
	const detectPhone = useBreakpoint('phone');

	useMount(() => {
		setIsPhone(detectPhone)
	})

	useEffect(() => {
		setIsPhone(detectPhone)
	}, [detectPhone])

	// Set colour theme

	const projects = {
		name: 'projects',
		primary: 'white',
		secondary: 'black',
		background: 'white',
		accent: 'white',
	};

	const journal = {
		name: 'journal',
		primary: 'black',
		secondary: 'white',
		background: 'black',
		accent: coolGrey,
	};

	const toggleFilter = (filter) => {
		if (!filters) return

		// Only allow one sorting filter at a time

		let newFilters = filters

		if (filter?.isSorting) {
			newFilters = filters.filter((item) => item.isSorting == false)
		}

		// Add or remove filter to state

		const exists = filters.find((item) => item.slug == filter.slug)

		if (exists) {
			newFilters = newFilters.filter((item) => item.slug !== filter.slug)
			setFilters(newFilters)
		} else {
			setFilters([
				...newFilters,
				filter
			])
		}
	}

	const resetFilters = (params) => {
		setFilters([])
	}

	const filterPosts = () => {
		const { posts, postType } = props;
		let initialPosts = posts
		let results = posts

		// Clear all results if category filter is selected, as we are using an additive filter

		const hasCategoryFilter = filters.find((filter) => filter.type == 'category')

		if (hasCategoryFilter) {
			results = []
		}

		// Reverse filters so that the last category filtered appears first

		let reversedFilters = filters.reverse()

		forEach(reversedFilters, (filter) => {

			// Category (Additive Filter)

			if (filter?.type == 'category') {
				forEach(initialPosts, (post) => {
					const cats = []

					if (post?.cats) cats.push(...post.cats)
					if (post?.disciplines) cats.push(...post.disciplines)
					if (post?.sectors) cats.push(...post.sectors)

					forEach(cats, (cat) => {
						if (cat.slug == filter.slug) {
							results.push(post)
						}
					})
				})
			}

			// Name (Order)

			if (filter?.type == 'name') {
				results = orderBy(results, 'title', 'asc')
			}

			// Date Filter (Order)

			if (filter?.type == 'date') {
				results = orderBy(results, (o) => {
					if (postType == 'news') {
						return o.publishedDateUnix
					} else {
						const year = parseInt(o.filter_year);

						if (o.filter_year !== "") {
							return year
						} else {
							return 0
						}
					}
				}, 'desc')
			}
		})

		return results
	}

	const getPostCount = (category) => {
		const { posts } = props;
		let count = 0;

		forEach(posts, (post) => {
			const cats = []

			if (post?.cats) cats.push(...post.cats)
			if (post?.disciplines) cats.push(...post.disciplines)
			if (post?.sectors) cats.push(...post.sectors)

			forEach(cats, (cat) => {
				if (cat.slug == category) {
					count++
				}
			})
		})

		if (count == 0) count = posts.length

		return (
			<div className='count'>
				{`(${count})`}
			</div>
		)
	}

	const renderFilters = () => {
		if (!props.filters) return

		const sortOptions = []

		const chronological = props.filters.find((filter) => filter.type == 'date')
		const alphabetical = props.filters.find((filter) => filter.type == 'name')

		if (chronological) sortOptions.push(chronological)
		if (alphabetical) sortOptions.push(alphabetical)

		const filteredFilters = props.filters.filter((filter) => {
			if(pageTheme === 'dark' && !isPhone) {
				return filter.type !== 'date' && filter.type !== 'name'
			}

			return props.filters
		})

		const categoryOptions = filteredFilters.map((filter, i) => {
			const active = filters.find((item) => item.slug == filter.slug)

			return (
				<Filter
					key={i}
					onClick={() => toggleFilter({
						...filter,
						type: filter?.type ? filter.type : 'category'
					})}
					className={active ? 'active' : ''}
				>
					<Heading dangerouslySetInnerHTML={{__html: filter.title}} />
					{/* {getPostCount(filter.slug)} */}
				</Filter>
			)
		})

		const sortingOptions = sortOptions.map((filter, i) => {
			const active = filters.find((item) => item.slug == filter.slug)

			return (
				<Filter
					key={i}
					onClick={() => toggleFilter({
						...filter,
						type: filter?.type ? filter.type : 'name'
					})}
					className={active ? 'active' : ''}
				>
					<Heading dangerouslySetInnerHTML={{__html: filter.title}} />
				</Filter>
			)
		})

		return (
			<Filters>
				<Toggle
					active={activeFilter}
				>
					<div className='toggle-header' onClick={() => setActiveFilter(!activeFilter) }>
						<Heading>Filter</Heading>
						<Icon />
					</div>

					<ToggleContent
						activeFilters={activeFilter}
						animate={activeFilter ? 'active' : 'hidden'}
						initial={'hidden'}
						variants={animatedOptions}
					>
						<Wrap>
							<Options>
								{categoryOptions}
							</Options>

							{pageTheme === 'dark' && !isPhone && (
								<Options>
									{sortingOptions}
								</Options>
							)}
						</Wrap>
					</ToggleContent>

					{filters?.length > 0 && (
						<Reset
							onClick={resetFilters}
						>
							Clear All
						</Reset>
					)}
				</Toggle>
			</Filters>
		)
	}

	const renderProject = (post) => {
		const column = columns[props.postType];

		post = {
			...post,
			...JSON.parse(post.acf_json)
		}

		console.log('post', post.listing)

		return (
			<Post
				key={post.title}
				as={!post.only_display_on_index && Link}
				to={`/projects/${post.slug}`}
				columnSizing={column.sizing}
			>
				{post.listing.use_image_or_video === 'image' && post.listing.image && (
					<Image
						src={post.listing.image && post.listing.image.sizes.medium2}
					/> 
				)}

				{post.listing.use_image_or_video === 'image' && !post.listing.image && (
					<BlankImage />
				)}

				{post.listing.use_image_or_video === 'video' && post.listing.video && (
					<Player 
						url={post.listing.video}
						controls={false}
						loop
						playing 
						muted
						playsinline
					/>
				)}

					<ProjectColumn>
						<Heading
							dangerouslySetInnerHTML={{__html: post.title}}
						/>

						<ProjectGroup className='project-group'>
							<Text
								className='client'
								dangerouslySetInnerHTML={{__html: post.projectdata.client.name}}
							/>

							<Text
								className='discipline'
								dangerouslySetInnerHTML={{__html: post.disciplines_primary.name}}
							/>

							<Text
								className='sector'
								dangerouslySetInnerHTML={{__html: post.sectors_primary.name}}
							/>

							<Text className='year'
								dangerouslySetInnerHTML={{__html: post.projectdata.year_end}}
							/>
						</ProjectGroup>
					</ProjectColumn>
			</Post>
		)
	}

	const renderNews = (post) => {
		const column = columns[props.postType];

		post = {
			...post,
			...JSON.parse(post.acf_json)
		}

		return (
			<Post
				as={Link}
				key={post.title}
				to={`/journal/${post.slug}`}
				columnSizing={column.sizing}
				isNews={true}
			>
				{post.listing && (
					<Image
						src={post.listing.image && post.listing.image.sizes.medium2}
					/>
				)}

				<ProjectColumn hasNoImage={!post.listing.image}>

					<Heading
						dangerouslySetInnerHTML={{__html: post.title}}
					/>

					<ProjectGroup className='news-group'>
						<Text
							className='category'
							dangerouslySetInnerHTML={{__html: post.primary_cat.name}}
						/>
						<Text className='date'>
							{moment.unix(post.publishedDateUnix).format('D MMMM YYYY')}
						</Text>
					</ProjectGroup>

				</ProjectColumn>
			</Post>
		)
	}

	const renderPosts = (params) => {
		if (!props.posts) return;

		const items = filterPosts().map((post, i) => {
			if (props.postType == 'project') return renderProject(post)
			if (props.postType == 'news') return renderNews(post)
		})

		return (
			<Posts>
				<Items>
					{items}
				</Items>
			</Posts>
		)
	}

	return (
		<ThemeProvider theme={pageTheme == 'dark' ? journal : projects}>
			<Wrapper
				ref={indexRef}
				{...props.variants}
			>
				<Breadcrumb>
					{breadcrumb}
				</Breadcrumb>
				{renderFilters()}
				{renderPosts()}
			</Wrapper>
		</ThemeProvider>
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``
const Icon = styled.div``
const Text = styled.div``
const Wrap = styled.div``

// Layout

const Wrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

`

const Container = styled.div`
	${container}
	${blockPadding}
`

// Breadcrumb

const Breadcrumb = styled.div`
	${breadcrumb}
	color: ${props => props.theme.primary};

`

// Filters

const Filters = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 160px;
	${blockPadding}

	${media.phone`
		padding-top: 100px;
	`}

	${Heading} {
		${subheading}
	}

	> ${Heading} {
		${padding}

		${media.phone`
			padding: 0 16px;
		`}
	}

	${Items} {
		display: flex;
		flex-direction: column;
		border-top: 1px dashed ${props => props.theme.primary};;
		margin-top: 20px;
	}
`

const Toggle = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	cursor: pointer;

	.toggle-header {
		display: flex;
		gap: 12px;
		align-items: center;
		width: fit-content;
		user-select: none;

		/* Supports hover */

		@media (hover: hover) {
			&:hover {
				${Heading} {
					color: ${props => props.theme.name == 'journal' ? '#E7E6E6': '#6B6C6F'};
				}
				
				${Icon} {
					opacity: ${props => props.theme.name == 'journal' ? 0.8 : 0.45};
					filter: ${props => props.theme.name == 'journal' ? 'invert(0)' : 'none'};
				}
			}
		}

		${Heading} {
			font-family: 'Formula';
			font-weight: 700;
			font-size: 72px;
			line-height: 74px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: ${props => props.theme.primary};

			${media.phone`
				font-size: 40px;
				line-height: 38px;
				font-weight: 500;
			`}
		}

		${Icon} {
			width: 35px;
			height: 35px;
			${bgIcon};
			transform: translateY(-8px);

			${props => {
				if (props.theme.name == 'journal') return css`
					filter: invert(1);
				`
			}}

			${media.phone`
				width: 18px;
				height: 18px;
				transform: translateY(-4px);
			`}
		}
	}

	${props => {
		if (props.active) return css`
			${Icon} {
				background-image: url(${require('../../assets/images/filter-minus.svg')});
			}
		`

		if (!props.active) return css`
			${Icon} {
				background-image: url(${require('../../assets/images/filter-plus.svg')});
			}
		`

		if (props.active && props.theme.name == 'journal') return css`
			${Icon} {
				background-image: url(${require('../../assets/images/filter-minus-black.svg')});
			}
		`

		if (!props.active && props.theme.name == 'journal') return css`
			${Icon} {
				background-image: url(${require('../../assets/images/filter-plus-black.svg')});
			}
		`
	}}
`

const Filter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	padding-bottom: 9px;
	user-select: none;
	border: 1px solid ${props => props.theme.primary};
	color: ${props => props.theme.primary};
	overflow: hidden;
	border-radius: 20px;

	${media.phone`
		padding: 8px 12px;
	`}

	&:hover {
		color: ${props => props.theme.name == 'journal' ? props.theme.accent : props.theme.secondary};
		background: ${props => props.theme.background};

		${Heading}, .count {
			color: ${props => props.theme.name == 'journal' ? props.theme.accent : props.theme.secondary};
		}
	}

	${Heading} {
		color: ${props => props.theme.primary};
		${h4}

		${media.phone`
			font-size: 13px;
		`}
	}

	.count {
		${h4}
		color: ${props => props.theme.primary};
		margin-left: 4px;

		${media.phone`
			font-size: 13px;
		`}
	}


	&.active {
		color: ${props => props.theme.accent};
		background: ${props => props.theme.background};

		${Heading}, .count {
			color: ${props => props.theme.name == 'journal' ? props.theme.accent : props.theme.secondary};
		}
	}
`

const Reset = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 10px;
	gap: 10px;
	background: transparent;
	width: fit-content;
	user-select: none;
	${h4}

	color: ${props => props.theme.name == 'journal' ? props.theme.accent : props.theme.secondary};
	border: 1px solid ${props => props.theme.primary};
	background: ${props => props.theme.primary};

	&:hover {
		background: transparent;
		color: ${props => props.theme.primary};
	}

	${media.phone`
		margin-top: 12px;
		font-size: 13px;
	`}
`

const animatedOptions = {
	hidden: {
		opacity: 0,
		height: 0,
		marginTop: 0,
		marginBottom: 0,
		pointerEvents: 'none',
		transition: {
			ease: 'easeOut'
		}
	},
	active: {
		opacity: 1,
		height: 'auto',
		marginTop: 10,
		marginBottom: 2,
		pointerEvents: 'auto',
		transition: {
			ease: 'easeOut'
		}
	}
}

const ToggleContent = styled(motion.div)`
	max-width: 1000px;

	${Wrap} {
		padding: 40px 0 30px;
		display: flex;
		flex-direction: column;
		gap: 12px;

		${media.phone`
			padding: 20px 0 0;
		`}
	}
`

const Options = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	max-width: 1000px;

	${Wrap} {
		display: flex;
	}

	${media.phone`
		gap: 10px;
	`}
`

const Option = styled.div`
	${Heading} {
		padding: 0;
		transition: color 0.25s ease;
		cursor: pointer;
	}

	&:not(:last-child) {
		margin-right: 34px;

		${media.phone`
			margin-right: 0;
			margin-bottom: 20px;
		`}
	}

	&:hover {
		${Heading} {
			color: black;
		}
	}

	${props => {
		if (props.active) return css`
			${Heading} {
				color: ${props => props.theme.primary};
			}
		`
	}}

	${media.phone`
		&:first-child {
			margin-top: 10px;
		}

		&:last-child {
			margin-bottom: 14px;
		}
	`}
`

// Posts

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 32px;
	position: relative;
	z-index: 2;

	${Heading} {
		${h2}
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: ${props => props.theme.primary};
	}

	${Subheading} {
		${h2}
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: ${props => props.theme.primary};;
	}

	${Text}{
		${h4}
	}

	${Items} {
		display: flex;
		flex-direction: column;
		border-top: 1px dashed ${props => props.theme.primary};;
		margin-top: 20px;

		${media.phone`
			margin-top: 0;

			> * {
				// height: 400px;
				flex: 0 1 256px;
			}
		`}
	}
`

const Columns = styled.div`
	display: flex;
	${padding};

	> ${Heading} {
		${props => props.columnSizing}
	}

	${media.phone`
		display: none;
	`}
`

const ProjectColumn = styled.div`
	${props => {
		if (props.hasNoImage) return css`
			${Heading} {
				flex: 0 1 34% !important;
			}
		`
	}}
`

const ProjectGroup = styled.div``

const Post = styled.div`
	border-bottom: 1px dashed ${props => props.theme.primary};
	${blockPadding}
	padding-top: 24px;
	padding-bottom: 24px;
	display: flex;
	gap: 40px;

	${media.tablet`
		flex-wrap: wrap;
	`}

	&:hover {
		${ProjectColumn} {
			${Heading}, ${Text}, .year {
				color: ${props => props.theme.name == 'journal' ? '#E7E6E6' : props.theme.primary};
			}
		}
	}

	${Text}, .year {
		${h4}
		color: ${props => props.theme.primary};
	}

	${ProjectColumn} {
		display: flex;
		width: 100%;
		gap: 20px;

		${media.tablet`
			flex: 1 0 50%;
			flex-direction: column;
			justify-content: space-between;
		`}

		${Heading} {
			${h2}
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: ${props => props.theme.primary};;
			flex: 0 0 20%;
			padding-right: 50px;
			min-width: 250px;

			${media.tablet`
				padding-right: 0;
				min-width: auto;
			`}
		}
	}

	${ProjectGroup} {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		flex: 1 1 auto;

		${media.tablet`
			flex: 0 1 auto;
			align-items: flex-end;
			gap: 8px 12px;
		`}

		${media.phone`
			gap: 8px 0;
		`}

		&.news-group {
			flex: 0 1 40%;
		}

		${Text} {
			flex: 1 0 20%;

			${media.tablet`
				flex-basis: calc(50% - 12px);
			`}

			${media.phone`
				font-size: 13px;
			`}

			&.discipline {
				align-self: flex-start;
			}

			&.date {
				text-align: right;

				${media.tablet`
					text-align: left;
				`}
			}

			&.year {
				flex: 1 0;

				${media.tablet`
					flex-basis: calc(50% - 12px);
				`}
			}
		}
	}

	${props => {
		if (props.isNews) return css`
			${ProjectColumn} {
				${Heading} {
					flex: 1 0 20%;
				}
			}
		`
	}}
`

const postFadeSettings = {
	bottom: true,
	distance: '30px',
}

// Hover Image

const animatedHover = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.25,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.25
		},
	},
}

const Transform = styled(motion.div)`
	will-change: transform;

	position: fixed;
	top: 45%;
	right: 0;
	left: 45%;
	bottom: 0;
	pointer-events: none;
	z-index: 0;
`

const Image = styled.img`
	width: auto;
    height: auto;
    max-width: 246px;
    max-height: 160px;
	object-fit: cover;
	min-width: 246px;

	${media.phone`
		min-width: 263px;
	`}
`

const BlankImage = styled.div`
	width: auto;
    height: auto;
    max-width: 256px;
    max-height: 160px;
	object-fit: cover;
	min-width: 246px;

	${media.phone`
		min-width: 263px;
	`}
`

// Video

const Player = styled(ReactPlayer)`
	width: 100% !important;
	height: 100% !important;
	pointer-events: none;	
	max-width: 246px;
    max-height: 160px;

	${media.phone`
		height: 368px !important;
	`}

	video {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
`

// Columns

const columns = {
	project: {
		items: [
			'Project',
			'For',
			'Sector',
			'Service',
			'Year',
			''
		],
		sizing: css`
			flex: 2;

			&:nth-child(1) {
				flex: 3;

				${media.tablet`
					flex: 5;
				`}
			}

			&:nth-child(2) {
				flex: 3;

				${media.tablet`
					display: none;
				`}
			}

			&:nth-child(3) {
				flex: 3;
			}

			&:nth-child(5) {
				flex: 1;
			}

			&:last-child {
				flex: 0 1 60px;
				text-align: right;

				${media.tablet`
					flex: 0 1 30px;
				`}
			}
		`
	},
	news: {
		items: [
			'Story',
			'Category',
			'Date',
			''
		],
		sizing: css`
			flex: 2;

			&:nth-child(1) {
				flex: 5;
			}

			&:nth-child(2) {
				flex: 2;
			}

			&:nth-child(3) {
				flex: 2;
			}

			&:last-child {
				flex: 0 1 60px;
				text-align: right;
			}
		`
	}
}

export default PostIndex
