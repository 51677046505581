import React, { useState } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { useLocation } from 'react-use';

import { media } from '../../styles/utils'
import { yellow, slateGrey, coolGrey } from '../../styles/colours'
import { container, bgIcon, padding, hoverState, bodyLink } from '../../styles/global'
import { legacyHeading2, subheading } from '../../styles/type';
import { getGlobalData } from '../../utils';

const Footer = (props) => {	
	const { style } = props;
	const { data } = getGlobalData('footer');

	const regular = {
		primary: 'black',
		secondary: 'white',
		background: 'white'
	};

	const projects = {
		primary: 'white',
		secondary: 'black',
		background: 'black'
	};

	const journal = {
		primary: 'black',
		secondary: 'white',
		background: coolGrey
	};

	const resolveTheme = () => {
		switch (style) {
			case 'projects':
				return projects;
			case 'journal':
				return journal;
			default:
				return regular;
		}
	}

	const scrollToTop = () => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
	}
	
	const renderInfo = () => {
		return (
			<Info>
				<Subheading
					dangerouslySetInnerHTML={{__html: data.phone}}
				/>

				<Subheading
					dangerouslySetInnerHTML={{__html: data.email}}
					href={`mailto:${data.email}`}
					as={'a'}
					target={'_blank'}
				/>

				<Subheading
					dangerouslySetInnerHTML={{__html: data.address}}
				/>
				
				<SocialLinks>
					{data.social_links.map((item, i) => {
						return (
							<Subheading
								key={i}
								as={'a'}
								target={'_blank'}
								href={item.link}
								dangerouslySetInnerHTML={{__html: item.title}}
							/>
						)
					})}
				</SocialLinks>
			</Info>
		)
	}
	
	return (
		<ThemeProvider theme={resolveTheme()}>
			<Wrap>
				<Wrapper>
					<Container>
						{renderInfo()}
						
						<Copyright>© Knott Black Pty Ltd</Copyright>

						<BackToTop
							onClick={scrollToTop}
						>
							Back to top
						</BackToTop>
					</Container>
				</Wrapper>
			</Wrap>
		</ThemeProvider>	
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrap = styled.div`
	padding-top: 128px;
	background-color: ${props => props.theme.background};
`

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	border-top: 1px dashed ${props => props.theme.primary};
`

const Container = styled.div`
	${container}
	${padding}
	height: 497px;
	padding-top: 33px;
	position: relative;
	max-width: none;

	${media.phone`
		padding: 17px 16px 0;
		flex-direction: column;
	`}

	${Info} {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;	
		color: ${props => props.theme.primary};

		a {
			${bodyLink}
			color: ${props => props.theme.primary};

			&:hover {
				color: black;
			}
		}

		${media.tablet`
			display: inline;
			max-width: 340px;

			> * {
				margin-right: 12px;
				line-height: 1.63;
				display: inline;
				word-break: break-all;
			}
		`}

		${media.phone`
			display: flex;
			flex-flow: row wrap;
			max-width: none;

			${Subheading}:nth-child(2) {
				margin-right: auto;
			}
		`}
	}
`

// Social Links

const SocialLinks = styled.div`
	display: flex;
	
	${Subheading} {	
		&:not(:last-child) {
			margin-right: 6px;
		}

		&:hover {
			color: black !important;
			opacity: 1;
			background: ${yellow};
		}

		color: ${props => props.theme.primary};
	}
`

// Fixed Items

const Copyright = styled.div`
	font-size: 10px;
	position: absolute;
	left: 32px;
	bottom: 30px;
	color: ${props => props.theme.primary};

	${media.phone`
		left: 16px;
		bottom: 13px;
		font-size: 12px;
	`}
`

const BackToTop = styled.div`
	${subheading}
	color: ${slateGrey};	
	cursor: pointer;

	position: absolute;
	right: 32px;
	bottom: 30px;

	${media.phone`
		right: 16px;
		bottom: 13px;
	`}
`

export default Footer
