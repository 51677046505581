import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { slateGrey } from '../../../styles/colours'

const BlockText = (props) => {
	const { content } = props;
        
	return (
		<Text
			dangerouslySetInnerHTML={{__html: content}}  
		/>
	)
}


const Text = styled.div`
	a {
		color: ${slateGrey};
		text-decoration: underline;
	}

	${media.phone`
		&, p {
			font-size: 12px;
			line-height: 1.67;
			letter-spacing: 0.36px;
		}
	`}
`

BlockText.wrapper = css`

`

export default BlockText
