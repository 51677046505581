import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { slateGrey, yellow } from '../../../styles/colours'
import { subheading } from '../../../styles/type'

const BlockImage = (props) => {
	const { image, use_caption, caption, ratio } = props;
	if (!image) return <></>
		
	return (
		<Wrapper
			ratio={ratio}
		>
			<ImageWrapper>
				<Image
					key={image?.sizes?.full_width}
					src={image?.sizes?.full_width}
				>
					{(src, loading) => {
						return (
							<BGImage 
								as={props.ratio == 'portrait' && 'img'}
								src={src} 
								style={{opacity: loading ? 0 : 1}}  
							/>
						)
					}}
				</Image> 

				{use_caption && caption && (
					<Container>
						<Caption
							dangerouslySetInnerHTML={{__html: caption}}
						/>
					</Container>
				)}
			</ImageWrapper>
		</Wrapper>
	)
}


const Container = styled.div`
	${container}
	${padding}
	padding: 0 96px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
`

const ImageWrapper = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.src});  
	${bgImage};
	transition: opacity 0.45s ease;
	height: 62.5vw;
	width: 100vw;
	background-color: grey;
`

const Caption = styled.div`
	margin-top: 23px;

	${media.phone`
		margin-top: 16px;
	`}

	&, a,  p {
		${subheading}
		color: ${slateGrey};
		letter-spacing: 0.12px;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${props => {
		if (props.ratio == 'portrait') return css`
			${BGImage} {
				width: auto;

				${media.phone`
					height: auto;
					width: 100%;
				`}
			}

			${Container} {
				padding: 0;

				${media.phone`
					${padding}
				`}
			}
		`
	}}

	${props => {
		if (props.ratio !== 'portrait') return css`			
			${media.phone`
				${BGImage} {
					min-height: 256px;
				}
			`}
		`
	}}
`


BlockImage.wrapper = css`
	${media.phone`
		margin-bottom: 42px;
	`}
`

export default BlockImage
