import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ProgressiveImage from 'react-progressive-image'

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth, bodyLink } from '../../../styles/global'
import { slateGrey } from '../../../styles/colours'
import { subheading, legacyHeading2, para1, body, h4 } from '../../../styles/type'
import { reverse } from 'lodash'

const BlockThreeColumnContent = (props) => {
    const { column_positions, media_items, text_content } = props;

    const renderMediaItems = () => {
        if (!media_items) return;

        const items = media_items.map((item, i) => {
            return (
                <ImageWrapper>
                    <Image
                        key={item?.image && item?.image.sizes.medium2}
                        src={item?.image && item?.image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <Img
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>

                    {item?.use_caption && item?.caption && (
                        <Caption
                            className={'caption'}
                            dangerouslySetInnerHTML={{ __html: item?.caption }}
                        />
                    )}
                </ImageWrapper>
            )
        })

        return (
            <Column className={'media-col'}>
                {items}
            </Column>
        )
    }

    const renderTextContent = () => {
        if (!text_content) return;

        return (
            <Column className={'text-col'}>
                <Text
                    dangerouslySetInnerHTML={{ __html: text_content }}
                />
            </Column>
        )
    }

    return (
		<Wrapper columnPosition={column_positions}>
            {renderMediaItems()}
            {renderTextContent()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Icon = styled.div``
const Spacer = styled.div``
const Text = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
`

const Img = styled.img`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	background: grey;
	display: flex;
    width: 100%;
`

// Layout

const Wrapper = styled.div`
	display: flex;
    gap: 8vw;
    justify-content: space-between;

    ${media.phone`
        gap: 40px;
    `}

    ${props => {
        if (props.columnPosition == 'text_left') return css`
            flex-direction: row-reverse;

            ${media.phone`
                flex-direction: column;
            `}
        `

        if (props.columnPosition == 'text_right') return css`
            flex-direction: row;

            ${media.phone`
                flex-direction: column;
            `}
        `
    }}
`

// Columns

const Columns = styled.div`
	display: flex;
	gap: 8vw;

	// ${media.tablet`
	// 	gap: 80px;
	// `}

	${media.phone`
		gap: 0;
		flex-direction: column;
	`}
`

const Column = styled.div`
	display: flex;

    ${Text}{
        p {
            ${body}

            a {
                ${bodyLink}
            }
        }

        h3 {
            font-weight: bold;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    
        li {
            ${body}
            
            &:before {
                content: '– ';
                color: #000;
            }
        }
    }

    &.text-col {
        max-width: 400px;
        flex-basis: 33.33%;

        ${media.tablet`
            max-width: 100%;
            flex: auto;
        `}
    }

    &.media-col {
        flex-basis: 66.66%;
        display: flex;
        gap: 40px;

        ${ImageWrapper}{
            flex-basis: 50%;
            flex-grow: 1;
        }

        ${media.tablet`
            flex-direction: column;
            gap: 20px;
            flex-shrink: 1;
            min-width: 240px;
        `}
    }

	${props => {
        // Proppies 
	}}
`

const Caption = styled.div`
	margin-top: 8px;

    ${media.phone`
        margin-top: 16px;
    `}

	&, p {
		font-size: 14px;
        line-height: 20px;
		letter-spacing: 0.12px;
	}

    a {
        ${bodyLink}
    }
`


BlockThreeColumnContent.wrapper = css`
    max-width: ${maxWidth}px;
    margin: 0 auto 160px;

    ${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 80px;
    `}
`

export default BlockThreeColumnContent