import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

const Block = (props) => {
	const { layout, lastBlock, block_type, inAccordion, display, wrapContainer} = props;
	const BlockLayout = resolveBlock(layout);
	
	if (!BlockLayout || !display) return <></>

	return (
		<Wrapper
			className={`block_${layout}`}
			layout={layout}
			blockType={block_type}
			blockProps={props}
			styles={BlockLayout && BlockLayout.wrapper}
			lastBlock={lastBlock}
			inAccordion={inAccordion}
		>
			{BlockLayout && (
				wrapContainer ? 
					<Container className={'block-container'}>
						<BlockLayout {...props}/>
					</Container>					
				:	
					<BlockLayout {...props}/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 100%;
	margin-bottom: 125px;
	max-width: 100vw;

	${media.phone`
		margin-bottom: 82px;
	`}

	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}

	/* Last Block */

	${props => {
		if (props.lastBlock) return css`
			margin-bottom: 0 !important;
		`
	}}

`

const Container = styled.div`
	${container}
	${padding}
	padding: 0 96px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;

	${media.tablet`
		${padding}
	`}
`


export default Block