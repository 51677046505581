import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { body, subheading, h4 } from './type';
import { slateGrey, yellow } from './colours';

export const maxWidth = 1512;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        font-family: "Swiss721BT";
        -webkit-font-smoothing: antialiased;
        color: black;
        /* overflow-y: overlay; */
        width: calc(100vw - 100%);
    }

    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        color: black;
    }

    p {
        ${body}

        a {
            ${hoverState}
        }
    }

    b, strong {
        font-weight: 600;
    }

    * {
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}
`;


export const container = css`
    display: flex;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    transition: color 0.25s ease;
    cursor: pointer;

    &:hover {
        color: ${slateGrey};
    }
`

export const bodyLink = css`
    text-decoration: underline;
    text-decoration-thickness: 1px;
    background-color: transparent;
    transition: background-color 0.25s ease;

    &:hover {
        background-color: ${yellow};
    }
`

export const padding = css`
    padding-left: 32px; 
    padding-right: 32px;
    box-sizing: border-box;

    ${media.phone`
        padding-left: 20px; 
        padding-right: 20px;
    `}
`

export const blockPadding = css`
    padding-left: 96px; 
    padding-right: 96px;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}

    ${media.phone`
        padding-left: 20px; 
        padding-right: 20px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const pagePadding = css`
    padding-top: 96px;

    ${media.phone`
        padding-top: 34px;
    `}
`

export const pageHeading = css`
    ${subheading};
    margin-bottom: 74px;

    ${media.phone`
        margin-bottom: 37px;
    `}
`

// Index Header

export const indexHeaderHeight = css`
    height: 93px;

    ${media.tablet`
        height: 76px;
    `}
`;


export const pagePaddingTopIndex = css`
   padding-top: 93px;

   ${media.tablet`
        padding-top: 76px;
    `}
`

export const breadcrumb = css`
    ${h4}
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    padding-top: 32px;
    padding-left: 96px; 
    padding-right: 96px;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
        font-size: 18px;
    `}

    ${media.phone`
        padding-top: 28px;
        padding-left: 20px; 
        padding-right: 20px;
        font-size: 13px;
    `}
`