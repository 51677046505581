import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { useLocation, useMount, useUnmount } from 'react-use';

import { PostIndex } from '../'

import { media, useBreakpoint } from '../../styles/utils'
import { yellow, coolGrey } from '../../styles/colours'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { legacyHeading2, h4 } from '../../styles/type';
import { withHeader } from './HeaderContext';

const Header = (props) => {
	const {headerVisible, indexVisible, indexActive, splashActive, setHeaderContext, headerBarMobile } = props
	const [menuActive, setMenuActive] = useState(false)
	const location = useLocation();
	const [isPhone, setIsPhone] = useState(false)
	const detectPhone = useBreakpoint('phone');

	useMount(() => {
		setIsPhone(detectPhone)
	})

	useEffect(() => {
		setIsPhone(detectPhone)
	}, [detectPhone])

	const toggleMenu = () => {
		setMenuActive(!menuActive)
		document.body.style.overflow = !menuActive == true ? 'hidden' : 'auto';
	}

	const toggleIndex = () => {
		setHeaderContext({indexActive: !indexActive})
	}

	useUnmount(() => {
		document.body.style.overflow = 'auto'
	})
	
	const renderNavItems = () => {	
		return (
			<>		
				<MenuToggle
					onClick={toggleMenu}
					active={menuActive}
				>
					{'MENU'}
				</MenuToggle>
			</>
		)
	}

	const renderMenuItems = () => {
		const menuItems = [
			{
				title: 'Home',
				link: '/',
			},
			{
				title: 'About',
				link: '/about',
			},
			{
				title: 'Projects',
				link: '/projects',
			},
			{
				title: 'People & Culture',
				link: '/people-and-culture',
			},
			{
				title: 'Journal',
				link: '/journal',
			},
			{
				title: 'Contact',
				link: '/contact',
			},
		]

		const items = menuItems.map((item, i) => {
			return (
				<MenuItem
					key={i}
					as={Link}
					to={item.link}	
					onClick={toggleMenu}
				>
					{item.title}
				</MenuItem>
			)
		})

		return (
			<MenuItems>{items}</MenuItems>
		)
	}

	const renderClose = () => {
		return (
			<Close
				onClick={toggleMenu}
				active={menuActive}
			>
				<Icon/>
			</Close>
		)
	}

	const renderFooter = () => {
		const address = "Wurundjeri Woi-Wurrung Country <br/> 6/114 Flinders St, Melbourne VIC 3000"
		const number = "Office +61 3 9671 4555"
		const email = "office@b-k-k.com.au"
	
		return (
			<Footer>
				<div className='column'>
					<Text dangerouslySetInnerHTML={{__html: address}}/>
				</div>

				<div className='column'>
					<Text dangerouslySetInnerHTML={{__html: number}}/>
					<Text as={Link} to='`mailto:${email}`'>{email}</Text>
					
					<div className='social'>
						<Text as={Link} to='https://au.linkedin.com/company/bkk-architects'>{'LinkedIn'}</Text>
						<Text as={Link} to='https://www.instagram.com/bkk_architects/'>{'Instagram'}</Text>
					</div>
				</div>
			</Footer>
		)
	}

	const renderMenu = () => {
		return (
			<Menu
				active={menuActive}
			>
				{renderClose()}

				<Container>
					{renderMenuItems()}
					{renderFooter()}
				</Container>
			</Menu>
		)
	}
	
	return (
		<Wrapper>
			<NavItem
				onClick={toggleMenu}
				splashActive={splashActive}
				headerVisible={headerVisible}
				menuActive={menuActive}
				style={
					{
						opacity: menuActive ? 0 : 1,
						pointerEvents: menuActive ? 'none' : 'auto',
					}
				}
			>
				{'menu'}
			</NavItem>

			{renderMenu()}
		</Wrapper>	
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Text = styled.div``
const Icon = styled.div`
	${bgIcon};
`


// Layout

const Container = styled.div`
	${container}
	${padding}
	position: relative;
	display: flex;
	max-width: none;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 10;

	> ${Container} {
		z-index: 11;
		justify-content: flex-end;
		padding-top: 24px;
		padding-right: 40px;
		position: relative;

		${media.phone`
			width: auto;
			margin-left: auto;
			padding-top: 16px;
			padding-right: 16px;
		`}
	}
`

// Menu Item

const NavItem = styled.div`
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background: ${yellow};
	font-size: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	text-transform: uppercase;

	position: fixed;
	top: 24px;
	left: calc(100vw - 42px - 42px);
	z-index: 11;

	${media.phone`
		top: 16px;
		left: calc(100vw - 42px - 16px);
	`}

	/* Animation */

	opacity: 0;
	pointer-events: none;
	transition: opacity 0.45s ease;

	${props => {
		if (props.headerVisible) return css`
			opacity: 1;
			pointer-events: all;
		`
	}}

	${props => {
		if (props.splashActive) return css`
			opacity: 0 !important;
		`
	}}
`

/* Menu */

const MenuToggle = styled.div`
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background: ${yellow};
	font-size: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;

	opacity: 0;
	transition: opacity 1s ease-in-out;
	pointer-events: none;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-right: 14px;
	}

	${props => {
		if (!props.active) return css`
			opacity: 1;
			pointer-events: all;
		`
	}}
`

const Close = styled.div`
	position: absolute;
	right: 38px;
	top: 38px;
	cursor: pointer;

	opacity: 0;
	transition: opacity 1s ease-in-out;
	transition-delay: 1s;

	${media.tablet`
		right: 28px;
		top: 28px;
	`}

	${media.phone`
		right: 16px;
		top: 16px;
	`}

	${Icon} {
		width: 34px;
		height: 34px;
		background-image: url(${require('../../assets/images/icon-close.svg')});

		${media.phone`
			right: 32px;
			top: 32px;
		`}
	}

	&:hover {
		${Icon} {
			background-image: url(${require('../../assets/images/icon-close-grey.svg')});

			${media.phone`
				right: 32px;
				top: 32px;
			`}
		}
	}

	${props => {
		if (props.active) return css`
			opacity: 1;
		`
	}}
`

const Menu = styled.div`
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 12;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 136px 81px 75px 81px;
	background: ${yellow};
	width: 90%;

	${media.tablet`
		width: 96%;
		padding: 136px 40px 38px;
	`}

	${Container} {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	/* Animation */

	opacity: 1;
	transform: translateX(-100%);
	pointer-events: none;
	transition: transform 1s ease-in-out;

	${props => {
		if (props.active) return css`
			opacity: 1;
			pointer-events: all;
			transform: translateX(0%);

			${MenuItems}, ${Footer} {
				opacity: 1;
			}
		`
	}}

	${media.phone`
		padding: 96px 20px 24px 20px;
	`}
`

const MenuItems = styled.div`	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	opacity: 0;
	transition: opacity 1s ease-in-out;
	transition-delay: 1s;
`

const MenuItem = styled.div`
	font-family: 'Formula';
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	letter-spacing: 0.05em;
	text-transform: uppercase;

	&:hover {
        color: ${coolGrey};
    }
	
	${media.phone`
        font-size: 40px;
		font-weight: 500;
    `}
`

// Footer

const Footer = styled.div`
	opacity: 0;
	transition: opacity 1s ease-in-out;
	transition-delay: 1s;

	display: flex;
	flex-direction: column;

	.column {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		${h4}
		line-height: 1.4;

		${media.phone`
			font-size: 13px;
		`}

		a {
			&:hover {
				color: ${coolGrey};
			}
		}

		&:first-child {
			padding-right: 32px;

			${media.phone`
				padding-right: 24px;
			`}
		}

		.social {
			display: flex;
			gap: 8px;
		}
	}
`

export default withHeader(Header)
