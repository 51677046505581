import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { useMount } from 'react-use';

import { media, useBreakpoint } from '../../styles/utils'
import Block from '../Block'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { paleGrey, coolGrey, slateGrey, placeholder } from '../../styles/colours'
import { subheading, legacyHeading2, legacyHeading3, h1, h4 } from '../../styles/type'

const TeamMemberProfile = (props) => {
    const { data, name } = props;
    const [isDevice, setIsDevice] = useState(false)
	const detectDevice = useBreakpoint('tablet');

    useMount(() => {
		setIsDevice(detectDevice)
	})

	useEffect(() => {
		setIsDevice(detectDevice)
	}, [detectDevice])

    const renderClose = () => {
		return (
			<Close
				onClick={props.close}
			>
				<Icon/>
			</Close>
		)
	}

    const renderImageSocial = (image) => {
        if(!data.image) return

        return (
            <Column className='left-col'>
                {isDevice && renderNamePosition()}

                <ImageWrapper>
                    <Image
                        key={data?.image && data?.image.sizes.medium2}
                        src={data?.image && data?.image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <Img 
                                    src={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image> 
                </ImageWrapper>

                {data?.show_linkedin && data?.linkedin_url && (
                    <Social
                        as={'a'}
                        href={data?.linkedin_url}
                        target={'_blank'}
                    >
                        LinkedIn
                        <Icon />
                    </Social>
                )}	
            </Column>
        )
    }

    const renderNamePosition = () => {
        if(!name) return

        return (
            <Content className='name-position'>
                <Heading
                    dangerouslySetInnerHTML={{__html: name}}
                />

                {data?.position && (
                    <Subheading
                        dangerouslySetInnerHTML={{__html: data?.position}}
                    />
                )}
            </Content>
        )
    }

    const renderBlocks = () => {
        if(!data?.blocks) return

        return data?.blocks.map((block, i) => {
            if(block?.acf_fc_layout === 'text') {
                return (
                    <Content key={i}>
                        <Text
                            dangerouslySetInnerHTML={{__html: block?.content}}
                        />
                    </Content>
                )
            }

            if(block?.acf_fc_layout === 'quotation') {
                return (
                    <Content key={i}>
                        <Text
                            className={'quote-text'}
                        >
                            {`${block?.quote_text}`}
                        </Text>
                    </Content>
                )
            }
        }
    )}

    const renderSelectedProjects = () => {
        if(!data?.selected_projects_display || !data?.selected_projects) return

        const items = data?.selected_projects.map((item, i) => {

            return (
                <Text as={Link} to={`/${item?.project?.post_name}`} key={i}>
                    <Text 
                        key={i}
                        dangerouslySetInnerHTML={{__html: item?.project?.post_title + ', '}} 
                    />
                    <Text 
                        key={i}
                        dangerouslySetInnerHTML={{__html: item?.project?.client_name}} 
                    />
                </Text>
            )
        })

        return (
            <Content>
                <Block
                    layout={'sub_heading'}
                    heading={'Selected Projects'}
                    display
                    white
                />

                <List>
                    {items}
                </List>
            </Content>
        )
    }

    return (
        <Wrapper>
            <Container>
                {renderClose()}
                {renderImageSocial()}

                <Column>
                    {!isDevice && renderNamePosition()}
                    {renderBlocks()}
                    {renderSelectedProjects()}
                </Column>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Icon = styled.div``
const Social = styled.div``
const Content = styled.div``
const Text = styled.div``
const List = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
    display: flex;
    flex-direction: column;
`

const Img = styled.img`
	${bgIcon};
	transition: opacity 0.45s ease;
	background: grey;
	width: 100%;
	height: 100%;
    object-fit: cover;
`

// Layout

const Wrapper = styled.div`
	display: flex;
    flex-direction: column;
    background: black;
    width: fit-content;
    height: 100vh;
    overflow-y: scroll;
    color: white;
    padding: 100px 80px;

    ${media.tablet`
        flex-direction: column;
        width: 97vw;
        padding: 100px 40px;
    `}

    ${media.phone`
        padding: 50px 20px;
        width: 95vw;
    `}
`

const Container = styled.div`
    display: flex;
    gap: 100px;
    width: 100%;

    ${media.tablet`
        flex-direction: column;
        gap: 70px;
    `}

    ${media.tablet`
        gap: 30px;
    `}

    ${Icon}{
        &:after {
            content: '';
            width: 18px;
            height: 18px;
            background-image: url(${require('../../assets/images/icon-arrow.svg')});
            background-repeat: no-repeat;

            ${media.tablet`
                height: 12px;
            `}
        }
    }
`

const Close = styled.div`
	position: absolute;
	right: 38px;
	top: 38px;
	cursor: pointer;
	opacity: 1;

	${media.tablet`
		right: 28px;
		top: 28px;
	`}

	${media.phone`
		right: 16px;
		top: 16px;
	`}

	${Icon} {
		width: 34px;
		height: 34px;
		background-image: url(${require('../../assets/images/icon-close-white.svg')});

		${media.phone`
			right: 32px;
			top: 32px;
		`}
	}
`


const Column = styled.div`
    padding-right: 40px;

    ${media.phone`
        padding-right: 0;
    `}

    &.left-col {
        flex-basis: 30%;
        flex-shrink: 0;

        ${media.tablet`
            flex-shrink: 1;
            padding-bottom: 70px;
        `}

        ${media.phone`
            padding-bottom: 0;
        `}
        
        ${ImageWrapper}{
            margin-bottom: 20px;
            max-width: 250px;

            ${media.tablet`
                max-width: 240px;
            `}

            ${media.phone`
                max-width: 150px;
            `}
        }

        ${Social}{
            display: flex;
            justify-content: space-between;
            align-items: center;
            ${h4}
            color: white;
            max-width: 250px;
            
            ${media.tablet`
                height: 12px;
                max-width: 240px;
            `}

            ${media.phone`
                max-width: 150px;
                font-size: 15px;
            `}

            &:after {
                content: '';
                width: 18px;
                height: 12px;
                background-image: url(${require('../../assets/images/icon-arrow-white.svg')});
                background-repeat: no-repeat;
            }
        }
    }

    ${Content}{
        max-width: 700px;
        padding-bottom: 12px;

        &.name-position {
            margin-bottom: 50px;

            ${media.phone`
                margin-bottom: 0;
            `}
        }

        ${Heading}{
            ${h1}
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 12px;

            ${media.tablet`
                margin-bottom: 0;
            `}
        }

        ${Subheading}{
            color: white;
            font-size: 26px;
            font-family: 'StyreneA';
            line-height: 1.3;

            ${media.phone`
                font-size: 18px;
            `}
        }

        ${Text}{
            margin-bottom: 32px;

            &, p, a {
                color: white;
            }

            &.quote-text {
              
                    font-size: 26px;
                    font-family: 'StyreneA';
                    line-height: 1.3;

                    ${media.tablet`
                        font-size: 18px;
                    `}
                }

                font-size: 26px;
                font-family: 'StyreneA';
                line-height: 1.3;

                ${media.tablet`
                    font-size: 18px;
                `}
            }

            &.quote-credit {
                ${h4}

                a {
                    text-decoration: underline;
                    color: ${slateGrey};
                }
            }
        }

        .block_sub_heading {
            margin: 0;
        }

        ${List}{
            display: flex;
            flex-direction: column;
            gap: 14px;

            ${Text} {
                color: white;
                margin-bottom: 0;

                font-family: 'StyreneA';
                font-size: 14px;

                display: flex;
                gap: 4px;

                ${media.phone`
                    font-size: 13px;
                `}

                &:hover {
                    color: ${coolGrey};
                }
            }
        }
    }

`

export default TeamMemberProfile