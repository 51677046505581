import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import Swiper from 'react-id-swiper'
import { sample, compact } from 'lodash'
import ReactPlayer from 'react-player'

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth, blockPadding, breadcrumb } from '../../../styles/global'
import { useMount } from 'react-use';
import { Link } from 'gatsby';
import { subheading, legacyHeading2, h4 } from '../../../styles/type'
import { paleGrey } from '../../../styles/colours'

const BlockSlider = (props) => {
	const {sliderStyles, controls, breadcrumb} = props;
	const [caption, updateCaption] = useState(null);
	const [activeIndex, updateActiveIndex] = useState(0);
	const [swiper, updateSwiper] = useState(null);
	const swiperRef = useRef();
	const slides = props.slides || props.content;
	const canLoop = slides && slides.length > 1;
	const isPhone = useBreakpoint('phone')
	
	const swiperParams = {
		getSwiper: updateSwiper,
		loop: canLoop,
		...props.sliderParams
	}

	useMount(() => {
		onSlideChange()
	})

	useEffect(() => {
		if (swiper) {
			swiper.on('slideChange', onSlideChange);
		}
	}, [swiper])

	useEffect(() => {
		if (swiperRef.current) {
			updateSwiper(swiperRef.current.swiper)
		}
	}, [swiperRef])

	useEffect(() => {
		if (props.activeSlide < 0 || !swiper) return;
		swiper.slideTo(props.activeSlide)
	}, [props.activeSlide])

	const onSlideChange = () => {
		updateActiveIndex(swiper ? (swiper.realIndex) : 0)
	}

	const wrapLink = (link, children) => {
		return (
			<Link
				to={link}
			>
				{children}
			</Link>
		)
	}

	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe.match(/src="(.+?)"/)[1]
		}
	}

	const renderImageSlide = (item, i) => {
		// const images = item.type == 'double_image' ? item.double_image : [item.single_image];
		const images = [item.single_image];

		const items = images?.map((image, i) => {
			if (!image) return;
			image = image.image || image;

			return (
				<BGImage 
					image={image.sizes && image.sizes.medium2} 
				/>
			)
		})

		return (
			<Slide
				key={i}
			>
				{compact(items)}
			</Slide>
		)
	}

	const renderVideoSlide = (item, i) => {
		const shouldContain = item?.video_contain
		
		return (
			<Slide
				key={i}
				layout={'video'}
			>
				{item.video && (
					<Video
						url={getVideoURL(item.video)}
						className={'media'}
						playing
						loop
						muted
						playsinline
						shouldContain={shouldContain == 1}
						styles={{
							width: '100%',
							height: '100%'
						}}
						config={{
							file: {
								attributes: {
									preload: 'auto'
								}
							}
						}}
					/>
				)}
			</Slide>
		)
	}

	const renderTextSlide = (item, i) => {
		if (isPhone) return;
		const { text, text_credit } = item
		
		return (
			<Slide
				key={i}
				layout={'text'}
			>
				<Info>
					<Description
						dangerouslySetInnerHTML={{__html: text}}
					/>	

					<Subheading
						dangerouslySetInnerHTML={{__html: text_credit}}
					/>
				</Info>	
			</Slide>
		)
	}
	
	const renderSlides = () => {
		if (!slides || !slides.length) return;

		const items = slides.map((item, i) => {
			if (item.type == 'text') return renderTextSlide(item, i)
			if (item.type == 'video') return renderVideoSlide(item, i)
			if (item.type !== 'text') return renderImageSlide(item, i)
		})

		return compact(items)
	}

	const renderCaption = (slide) => {
		if (!slide.caption) return;

		return (
			<Caption
				dangerouslySetInnerHTML={{__html: slide.text}}
			/>
		)		
	}

	const renderControls = () => {
		if (!slides || !slides.length) return;
		const activeSlide = slides[activeIndex];

		return (
			<>
				<PrevNext>
					<Prev
						onClick={() => swiper && swiper.slidePrev()}
					/>
					<Next
						onClick={() => swiper && swiper.slideNext()}
					/>
				</PrevNext>

				{renderCaption(activeSlide)}
			</>
		)
	}	

	return (
		<Wrapper
			sliderStyles={sliderStyles}
		>
			{breadcrumb && (
				<Breadcrumb>
					{breadcrumb}
				</Breadcrumb>
			)}

			<Swiper
				ref={swiperRef}
				{...swiperParams}
			>
				{props.renderSlides && props.renderSlides(slides) || renderSlides()}
			</Swiper>

			{renderControls()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const InternalLink = styled(Link)``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const defaultSliderStyles = css`
	height: 62.5vw;
	width: 100vw;

	${media.phone`
		min-height: 256px;
	`}
`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles}
	}
`

// Breadcrumb

const Breadcrumb = styled.div`
	${breadcrumb}
	color: white;
`

// Slide

const Slide = styled.div`
	display: flex;

	${BGImage} {
		height: 100%;
		flex: 1;
		filter: brightness(0.85);
	}

	/* Text Slide */

	${props => {
		if (props.layout == 'text') return css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: ${paleGrey};

			${Info} {
				max-width: 736px;

				${Description} {
					${legacyHeading2}
				}

				${Subheading} {
					${subheading}
					margin-top: 55px;
				}
			}
		`

		if (props.layout == 'video') return css`
			background: black;
		`
	}}
`

const Video = styled(ReactPlayer)`
	width: 100% !important;
	height: 100% !important;
	pointer-events: none;

	video {
		object-fit: cover;
	}

	${props => {
		if (props.shouldContain) {
			return css`
				video {
					object-fit: contain;
				}
			`
		}
	}}
`

// Prev / Next

const Next = styled.div``
const Prev = styled.div``
const Divider = styled.div``

const PrevNext = styled.div`
	${Next}, 
	${Prev} {
		z-index: 9;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 50vw;
	}

	${Next} {
		right: 0;
		cursor: -webkit-image-set(url(${require('../../../assets/images/slider-arrow-left.png')}) 2x) 52 31,e-resize;
	}

	${Prev} {
		left: 0;
		cursor: -webkit-image-set(url(${require('../../../assets/images/slider-arrow-right.png')}) 2x) 52 31,e-resize;
	}

	${media.tablet`
		display: none;
	`}
` 

// Controls

const Count = styled.div`
	position: absolute;
	right: 32px;
	bottom: 32px;
	z-index: 2;
	${subheading}
	color: white;
`

const Caption = styled.div`
	position: absolute;
	left: 32px;
	bottom: 32px;
	z-index: 2;
	
	&, p {
		${subheading}
		color: white;
	}
`

BlockSlider.wrapper = css``

export default BlockSlider
