import React, { Component, useState } from 'react'
import styled, { css, createGlobalStyle, keyframes } from 'styled-components';
import { motion } from 'framer-motion'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Lottie from 'react-lottie';
import moment from 'moment'

import { hoverState, bgImage, bgIcon } from '../../styles/global';
import { isClient, media, useBreakpoint } from '../../styles/utils';

import { useMount } from 'react-use';
import { withHeader } from '../Header/HeaderContext';

import animation from './logo-anim.json'

const Splash = (props) => {
    const [animate, setAnimate] = useState(false)
    const isPhone = useBreakpoint('phone');
    let timer;

    const hasSeenIntro = () => {
        if (!isClient()) return

        const introSeenDate = localStorage.introSeenDate && moment.unix(parseInt(localStorage.introSeenDate));
        const timeNow = moment()
        const introDiff = timeNow?.diff?.(introSeenDate, 'days') 
        const introSeen = localStorage.introSeen == 'true' && localStorage.introSeenDate && introDiff == 0;

        return introSeen
    }
    

    useMount(() => {
        if (isClient()) {
            let splash = document.getElementById('splash')	
            const introSeen = hasSeenIntro();

			if (introSeen) { 	
                if (splash) {
                    splash.classList.add('transition')
                    splash.classList.remove('active')
                    document.body.style.overflow = 'auto';
                }

                props.setHeaderContext({
                    splashActive: false
                })

                setTimeout(() => {
                    localStorage.introSeen = true;
                }, 650);
                
                enableBodyScroll()
            } else {
              
                if (splash) {
                    splash.classList.add('active');
                    document.body.style.overflow = 'hidden';
                }

                if (!isPhone) {
                    setTimeout(() => {
                        setAnimate(true)
                    }, 3000);
                } else {
                    setTimeout(() => {
                        seenSplash()
                    }, 5000);
                }

			}
        }
    })

	const seenSplash = () => {
        const introSeen = hasSeenIntro();

        if (!introSeen) {
			clearInterval(timer);

            let splash = document.getElementById('splash')	
            
            if (splash) {
                splash.classList.add('transition')
                splash.classList.remove('active')
            }

            props.setHeaderContext({
                splashActive: false
            })

            document.body.style.overflow = 'auto';
            localStorage.introSeen = true;
            localStorage.introSeenDate = moment().unix();
	   }
	}

    const renderLogo = () => {
        const animOptions = {
            loop: false,
            autoplay: true,
            animationData: animation,
        }

		return (
			<LogoWrapper>
                {animate && (
                    <Lottie 
                        isClickToPauseDisabled={true}
                        options={animOptions} 
                        eventListeners={
                            [
                                {
                                    eventName: 'complete',
                                    callback: seenSplash
                                }
                            ]
                        }
                    />
                )}
			</LogoWrapper>
		)
    }
    
    return ( 
        <>
            <SplashStyles/>
            <Wrapper
                id={'splash'}
            >
                {renderLogo()}
            </Wrapper>
        </>
    )
}


// Layout

const Wrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: black;
    z-index: 22;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
`

// Logo

const LogoWrapper = styled(motion.div)`
	position: absolute;
	z-index: 2;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 600px;
    height: auto;

    ${media.tablet`
        width: 420px;
    `}

    ${media.phone`
        width: 300px;
        height: 135px;
        background-image: url(${require('../../assets/images/splash-mobile.svg')});
        ${bgIcon}
    `}

    ${media.phoneS`
        width: 260px;
    `}
`

const SplashStyles = createGlobalStyle`
    #splash {
	    opacity: 0;
        pointer-events: none;
		
        &.active {
            opacity: 1;
            pointer-events: all;
		}

		&.transition {
            transition: opacity 0.65s ease;
		}
    }
`


export default withHeader(Splash);
