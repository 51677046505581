import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth, bodyLink } from '../../../styles/global'
import { legacyHeading2, subheading, body } from '../../../styles/type'
import { slateGrey } from '../../../styles/colours'

const BlockIntro = (props) => {
    const { intro_text, body_text, meta_data, image } = props;

    const renderText = (props) => {
        return (
            <Content>
                {intro_text && (
                    <Heading dangerouslySetInnerHTML={{__html: intro_text}} />
                )}

                {body_text && (
                    <Text dangerouslySetInnerHTML={{__html: body_text}} />
                )}
            </Content>
        )
    }

    const renderMetaData = () => {
        if (!meta_data) return;

        const items = meta_data?.map((item, index) => {
            return (
                <Item key={index}>
                    <Subheading dangerouslySetInnerHTML={{__html: item.title}} />
                    <Text dangerouslySetInnerHTML={{__html: item.value}} />
                </Item>
            )
        })

        return (
            <Meta>
                {items}
            </Meta>
        )
    }

    const renderImage = () => {
        if (!image) return;

        return (
            <Image
                key={image && image.sizes.medium2}
                src={image && image.sizes.medium2}
            >
                {(src, loading) => {
                    return (
                        <img 
                            src={src} 
                            style={{opacity: loading ? 0 : 1}}  
                        />
                    )
                }}
            </Image> 
        )
    }

	return (
        <Wrapper>
            <Columns className='first-col'>
                {renderText()}
            </Columns>
            <Columns className='last-col'>
                {renderMetaData()}
                {renderImage()}
            </Columns>
        </Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Item = styled.div``
const Text = styled.div``

// Utility

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

// Layout

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 80px;

    ${media.phone`
        flex-direction: column;
        gap: 40px;
    `}
`

// Columns

const Columns = styled.div`
    display: flex;
    flex-direction: column;

    &.first-col {
        flex-basis: 70%;
    }

    &.last-col {
        flex-basis: 30%;
        max-width: 300px;

        ${media.tablet`
            max-width: 200px;
        `}

        ${media.phone`
            max-width: 100%;
            flex-direction: column-reverse;
        `}
    }

    ${Heading}{
        p {
            ${legacyHeading2}
        }
    }
`

// Content

const Content = styled.div`
    ${Heading}{
        p {
            ${legacyHeading2}
        }
    }

    ${Text}{
        p {
            ${body}

            ${media.phone`
                font-size: 13px;
                line-height: 1.6;
            `}
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    
        li {
            ${body}
            
            &:before {
                content: '– ';
                color: #000;
            }
        }

        a {
            ${bodyLink}
        }
    }
`

// Meta

const Meta = styled.div`
    margin-bottom: 20px;

    ${media.phone`
        margin-top: 40px;
        margin-bottom: 0;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    `}

    ${Item}{
        margin-bottom: 24px;

        ${media.phone`
            flex-basis: 47%;
            margin-bottom: 0;
        `}

        ${Subheading}{
            margin-bottom: 4px;

            ${media.phone`
                font-size: 13px;
            `}
        }

        ${Text}{
            font-weight: 700;

            ${media.phone`
                font-size: 13px;
            `}
        }
    }
`

BlockIntro.wrapper = css`
    max-width: ${maxWidth}px;
    margin: 0 auto 160px;

    ${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 160px;
    `}
`

export default BlockIntro
