import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition';
import 'swiper/css/swiper.css'

import { MetaTags, Header, Footer } from '../'
import { media, isClient } from '../../styles/utils';
import { withHeader } from '../Header/HeaderContext';

const Layout = ({ children, meta, ...props }) => {
	const { splashActive, footerTheme } = props
	return (
		<>
			<MetaTags
				{...meta}
			/>

			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet> 

			<Header
				headerBarMobile={props.headerBarMobile}
			/>

			<Wrapper>
				<Transition>
					{(isClient() && !splashActive || !isClient()) && children}
				</Transition>
			</Wrapper>

			<Footer style={footerTheme}/>
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: calc(100vh - 626px); 
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;

	${media.phone`
		min-height: 100vh;
	`}
`

export default withHeader(Layout)
