import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { slateGrey, yellow } from '../../../styles/colours'
import { subheading } from '../../../styles/type'

const BlockImageHotspot = (props) => {
	const { use_caption, caption , hotspots} = props;
	const image = props.image || props.content;
	if (!image) return <></>
		
	return (
		<Wrapper>
			<ImageWrapper>
				<Image
					key={image}
					src={image}
				>
					{(src, loading) => {
						return (
							<Img 
								className={'hotspot-img'}
								src={src} 
								style={{opacity: loading ? 0 : 1}}  
							/>
						)
					}}
				</Image> 
				
				{hotspots && renderHotSpots(hotspots)}

				{use_caption && caption && (
					<Caption
						className={'caption'}
						dangerouslySetInnerHTML={{__html: caption}}
					/>
				)}
			</ImageWrapper>		
		</Wrapper>
	)
}

const renderHotSpots = (hotspots) => {
	const items = hotspots.map((item, i) => {
		return (
			<Hotspot 
				key={i}
				style={{
					left: `${parseInt(item.x)}%`,
					top: `${parseInt(item.y)}%`,
				}}
			>
				<Description
					dangerouslySetInnerHTML={{__html: item.text}}
				/>
			</Hotspot>
		)
	})
	
	return items
}


const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Container = styled.div`
	${container}
	${padding}
	padding: 0 96px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
`

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`


const ImageWrapper = styled.div`
	position: relative;
`

const Img = styled.img`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	background: grey;
	display: flex;
`

const Caption = styled.div`
	margin-top: 23px;

	&, p {
		${subheading}
		color: ${slateGrey};
		letter-spacing: 0.12px;
	}
`

// Image Hotspots

const Description = styled.div``

const Hotspot = styled.div`
	position: absolute;
	z-index: 2;
	background: ${yellow};
	width: 14px;
	height: 14px;
	border-radius: 50%;
	cursor: pointer;

	${Description} {
		color: ${yellow};
		${subheading};
		
		position: absolute;
		top: 0;
		padding-bottom: 6px;
		left: 0;
		transform: translateY(-100%);
		width: 120px;
		opacity: 0;
		transition: opacity 0.25s ease;
		pointer-events: none;
	}

	&:hover {
		${Description} {
			opacity: 1;
		}
	}

	${media.phone`
		display: none;
	`}
`

BlockImageHotspot.wrapper = css`

`

export default BlockImageHotspot
