import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { useLocation } from 'react-use';

import { media } from '../../styles/utils'
import { yellow, slateGrey } from '../../styles/colours'
import { container, bgIcon, padding, hoverState, indexHeaderHeight } from '../../styles/global'
import { heading2, subheading } from '../../styles/type';
import { getGlobalData } from '../../utils';
import { withHeader } from '../Header/HeaderContext';

const IndexHeader = (props) => {	
    const { indexActive, setHeaderContext, hideIndexHeaderText, headerBarMobile } = props;

    const toggleIndex = () => {
        setHeaderContext({
            indexActive: !indexActive
        })
    }
    
	return (
		<Wrapper>
			<Container
                hideIndexHeaderText={headerBarMobile}
            >
				<Info>
                    <Subheading
                        onClick={toggleIndex}
                        active={!indexActive}
                    >
                        Featured
                    </Subheading>

                    <Subheading
                        onClick={toggleIndex}
                        active={indexActive}
                    >
                        View All
                    </Subheading>
                </Info>
			</Container>
		</Wrapper>	
	)
}

// Shared

const Heading = styled.h1``
const Description = styled.div``
const Info = styled.div``

const Subheading = styled.h2`
    color: ${slateGrey};
    transition: color 0.25s ease;
    cursor: pointer;
    
    &:hover {
        color: black;
    }

    ${props => props.active && css`
        color: black;
    `}
`

// Layout

const Wrapper = styled.div`
	display: flex;
	justify-content: center;

    position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 9;
    background: white;
`

const Container = styled.div`
	${container}
	${padding}
	${indexHeaderHeight}
	position: relative;
	max-width: none;
    align-items: center;

	${Subheading} {
		${subheading}

        &:first-child {
            margin-right: 24px;
        }
	}

    ${Info} {
        display: flex;
    }

    ${media.phone`
        padding-left: 16px; 
        padding-right: 16px;
    `}

    ${props => {
        if (props.hideIndexHeaderText) return css`
            ${Subheading} {
                display: none;
            }
        `
    }}
`

export default withHeader(IndexHeader)
