import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { paleGrey, coolGrey, slateGrey, placeholder } from '../../../styles/colours'
import { subheading, legacyHeading2, legacyHeading3 } from '../../../styles/type'

const BlockGridItem = (props) => {
	const { acf_fc_layout, overlay, itemType, switch_left_right, flip } = props
	
	const renderOverlay = () => {
		const { type, content } = overlay;
		const layout = layouts[acf_fc_layout];
				
		return (
			<Overlay
				type={type}
				height={layout && layout.height}
				as={type == 'opportunity' ? Link : 'a'}
				to={'/contact#opportunities'}
				href={type == 'instagram' && content && content.permalink}
				target={'_blank'}
				flipped={switch_left_right && !flip}
				style={{
					...layout.overlay
				}}
			>
				{type == 'opportunity' && (
					<>
						<Subheading>Opportunity</Subheading>
						<Heading>{content.title}</Heading>
						<Subheading>{content.prompt_text}</Subheading>
					</>
				)}

				{type == 'instagram' && content && (
					<>
						<Image
							key={content.media_url && content.media_url}
							src={content.media_url && content.media_url}
						>
							{(src, loading) => {
								return (
									<BGImage 
										image={src} 
										style={{opacity: loading ? 0 : 1}}  
									/>
								)
							}}
						</Image> 
						<Heading>Instagram</Heading>
					</>
				)}
			</Overlay>
		)
	}
	
	const renderColumn = (posts, side) => {
		if (!posts) return;
		const layout = layouts[acf_fc_layout];
		
		const items = posts.map((item, i) => {
			item.type = item.type || itemType
			const hasOverlay = overlay && overlay.position == side && layout.overlay && layout.overlay.left == 0
			return renderPost(item, i, false, hasOverlay, switch_left_right && !flip)
		})		

		return (
			<Items
				width={layout && layout[side]}
				height={layout && layout.height}
				itemCount={items.length}
			>
				{items}
				{overlay && overlay.position == side && renderOverlay()}
			</Items>
		)
	}

	const renderColumns = () => {
		const columns = [
			{
				posts: props.left,
				side: 'left'
			},
			{
				posts: props.right,
				side: 'right'
			},
		]

		if (switch_left_right) {
			columns.reverse()
		}

		if (flip) {
			columns.reverse()
		}

		return columns.map((item, i) => {
			return renderColumn(item.posts, item.side)
		})
	}
        
	return (
		<Wrapper>
			{renderColumns()}
		</Wrapper>
	)
}

const renderRollover = (item) => {
	const { acf, title, slug, primary_cat, cats } = item.content;
	const display = item.image_or_text == 'text';	

	const info = {
		subheading: item.type == 'news' && primary_cat ? primary_cat.name : title,
		heading: item.type == 'news' ? title : acf.content_title,
		bottom: item.type == 'news' ? 'Read more' : acf.projectdata && acf.projectdata.client && `For ${acf.projectdata.client.name}`
	}

	return (	
		<Info		
			display={display}
			theme={display && infoThemes[item.background_colour]}
			mobileTheme={item.text_colour}
		>
			{info.subheading && (
				<Subheading
					dangerouslySetInnerHTML={{__html: info.subheading}}
				/>
			)}

			<Heading
				dangerouslySetInnerHTML={{__html: info.heading}}
			/>

			{info.bottom && (
				<Subheading
					className={'caption'}
				>
					{info.bottom}
				</Subheading>
			)}
		</Info>
	)
}

const renderMobileTitles = (item) => {
	const { acf, title, slug, primary_cat, cats, type } = item.content;
	const display = item.image_or_text == 'text'	
	const theme = acf.listing.text_colour;

	return (	
		<MobileTitles		
			theme={acf.listing.text_colour}
		>
			{item.type == 'news' && primary_cat && (			
				<Subheading
					dangerouslySetInnerHTML={{__html: primary_cat.name}}
				/>
			)}

			{title && (
				<Subheading
					dangerouslySetInnerHTML={{__html: title}}
				/>
			)}

			{/* {acf.content_title && (
				<Subheading
					dangerouslySetInnerHTML={{__html: acf.content_title}}
				/>
			)} */}
		</MobileTitles>
	)
}

const resolveLink = (item) => {
	const slug = {
		project: `projects`,
		news: 'journal'
	}		

	return `/${slug[item.type]}/${item.content.slug}`
}

const resolveCategoryLink = (item) => {
	const slug = {
		project: `projects`,
		news: 'journal'
	}		

	return `/${slug[item.type]}/${item.content.primary_cat.slug}`
}


export const renderPost = (item, i, disableVideo, hasOverlay, flipped) => {
	if (!item.content || !item.content.acf) return
	const { acf, title, slug, primary_cat, cats } = item.content;
	let { use_image_or_video, image, video } = acf.listing;
	if (acf.only_display_on_index) return;

	return (
		<Item 
			key={i}
			as={Link}
			to={resolveLink(item)}
			flipped={flipped}
			hasOverlay={hasOverlay}
		>
			{(use_image_or_video !== 'video' || disableVideo) && item.image_or_text !== 'text' && (
				<Image
					key={image && image.sizes.medium2}
					src={image && image.sizes.medium2}
				>
					{(src, loading) => {
						return (
							<BGImage 
								image={src} 
								style={{opacity: loading ? 0 : 1}}  
							/>
						)
					}}
				</Image> 
			)}

			{!disableVideo && use_image_or_video == 'video' && item.image_or_text !== 'text' && (
				<Player 
					url={video}
					controls={false}
					loop
					playing 
					muted
					playsinline
				/>
			)}

			{renderRollover(item)}
			{renderMobileTitles(item)}
		</Item>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-color: ${placeholder};
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	display: flex;

	${media.phone`
		flex-direction: column;
	`}
`

// Items

const Items = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	height: ${props => props.height || 50}vw;

	${props => {
		return css`
			flex: 1 0 ${props.width};
		`
	}}
	
	${media.phone`
		height: auto;

		${props => {
			if (props.width == '33%' && props.itemCount == 1) return css`
				> *:first-child {
					flex: 0 1 512px;
				}
			`
		}}

		${props => {
			if (props.width == '50%' && props.itemCount == 1) return css`
				> *:first-child {
					flex: 0 1 368px;
				}
			`
		}}
	`}	
`

const Info = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 16px;

	display: flex;	
	flex-direction: column;
	 
	${Subheading} {
		${subheading}

		&:first-child {
			margin-bottom: 28px;
		}
	}

	${Heading} {
		${legacyHeading2}
		margin-bottom: auto;

		${media.tablet`
			font-size: 16px;
		`}
	}

	/* Toggle Display */

	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s ease;
	z-index: 3;

	${props => {
		if (props.display) return css`
			opacity: 1 !important;
			pointer-events: all;
		`
	}}

	/* Themes */

	background: black;
	color: white;

	${props => {
		if (props.theme) return css`
			background: ${props.theme.background};
			
			&, 
			${Subheading},
			${Heading} {
				color: ${props.theme.text};
			}
		`
	}}

	&, > * {
		transition: all 0.5s ease;
	}

	&:hover {
		background: black;

		&, 
		${Subheading},
		${Heading} {
			color: white;
		}
	}
`

// Mobile Titles
 
const MobileTitles = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 16px;
	z-index: 2;

	display: flex;	
	flex-direction: column;

	br {
		display: none;
	}
	 
	${Subheading} {
		${subheading}
	}

	${props => {
		if (props.theme) return css`			
			${Subheading} {
				color: ${props.theme};
			}
		`
	}}

	/* Display */
	display: none;

	${media.phone`
		display: flex;
	`}
`

// Item

const Item = styled.div`
	position: relative;
	flex: 1;
	cursor: pointer;	
	height: 100%;

	${BGImage} {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
	}

	&:hover {		
		${Info} {
			opacity: 1;
			pointer-events: all;

			${media.phone`
				opacity: 0;
			`}
		}
	}

	${media.tablet`
		${Subheading} {
			font-size: 8px;
		}
	`}

	${media.phone`
		${Subheading} {
			${subheading}
		}

		width: 100vw;
		flex: 0 1 256px;
	`}

	/* Overlay Heading Shift */

	.caption {
		transition: none;
	}

	${props => {
		if (props.hasOverlay) return css`
			&:hover {
				.caption {
					transform: translateY(-25vw) !important;
				}
			}
		`
	}}

	${props => {
		if (props.hasOverlay && props.flipped) return css`
			&:hover {
				.caption {
					transform: none !important;
				}
			}
		`
	}}
`

// Overlay

const Overlay = styled.div`
	position: absolute;	
	width: 320px;
	height: 320px;
	z-index: 3;
	height: ${props => (props.height || 50) / 2}vw;
	width: ${props => (props.height || 50) / 2}vw;

	/* Opportunity */

	${props => {
		if (props.type == 'opportunity') return css`
			padding: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background: white;
			transition: background 0.5s ease;

			${Subheading} {
				${subheading}
				
				${media.tablet`
					font-size: 8px;
				`}

				${media.phone`
					${subheading}
				`}

				&:first-child {
					margin-bottom: auto;
				}
			}

			${Heading} {
				${legacyHeading3}
				text-align: center;

				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 4.45vw;

				${media.tablet`
					font-size: 34px;
					line-height: 1.29;
				`}

				${media.phone`
					${legacyHeading3}
				`}
			}

			${Subheading},
			${Heading} {
				transition: color 0.5s ease;
			}

			&:hover {
				background: black;

				${Subheading},
				${Heading} {
					color: white;
				}
			} 
		`
	}}

	${props => {
		if (props.type == 'instagram') return css`
			background: black;

			${BGImage} {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: 3;
				transition: opacity 0.5s ease;
			}

			${Heading} {
				${legacyHeading3}
				text-align: center;

				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 4.45vw;
				margin-top: 0.6vw;
				color: white;
				
				${media.tablet`
					font-size: 34px;
					line-height: 1.29;
				`}

				${media.phone`
					${legacyHeading3}
				`}
			}

			&:hover {
				${BGImage} {
					opacity: 0 !important;
				}
			}
		`
	}}

	${props => {
		if (props.style && props.style.left == 0 && props.flipped) return css`
			right: 0 !important;
			left: auto !important;
		`
	}}

	${media.phone`
		position: relative;
		height: 100vw;
		width: 100vw;
	`}
`

// Video

const Player = styled(ReactPlayer)`
	width: 100% !important;
	height: 100% !important;
	pointer-events: none;	

	${media.phone`
		height: 368px !important;
	`}

	video {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
`

BlockGridItem.wrapper = css`
	margin-bottom: 0 !important;
`

// Grid Item Layouts

const layouts = {
	block_a: {
		left: '66%',
		right: '33%',
		overlay: {
			left: 0,
			bottom: 0,
		}
	},
	block_b: {
		left: '50%',
		right: '50%',
		overlay: {
			right: 0,
			bottom: 0,
		}
	},
	block_c: {
		left: '33%',
		right: '66%',
		overlay: {
			right: 0,
			bottom: 0,
		}
	},
	block_d: {
		left: '50%',
		right: '50%',
		height: 25 
	},
	block_e: {
		left: '33%',
		right: '66%',
		overlay: {
			left: 0,
			bottom: 0,
		}
	},
	block_f: {
		left: '50%',
		right: '50%'
	},
	block_g: {
		left: '66%',
		right: '33%',
		overlay: {
			right: 0,
			bottom: 0,
		}
	},
}

// Text Box Themes 

const infoThemes = {
	white: {
		background: 'white',
		text: 'black'
	},
	paleGrey: {
		background: paleGrey,
		text: 'black'
	},
	coolGrey: {
		background: coolGrey,
		text: 'white'
	},
	slateGrey: {
		background: slateGrey,
		text: 'white'
	},
}

export default BlockGridItem
